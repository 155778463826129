import { AddIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Flex,
  FormControl,
  Heading,
  IconButton,
  Image,
  Text,
  useToast,
} from "@chakra-ui/react";
import AppAsyncSelect from "components/AppAsyncSelect";
import AppNumberInput from "components/AppNumberInput";

import { useFormik } from "formik";
import { useApi } from "hooks/useApi";
import { useCache } from "hooks/useCache";
import { useContext, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Store } from "store";
import { showToast, stringifyErrors, toastTypes } from "utils";
import * as Yup from "yup";

export default function ManageQrCodePage() {
  const { id } = useParams();
  const toast = useToast();
  const navigate = useNavigate();
  const { dispatch } = useContext(Store);
  const [productKeyword, setProductKeyword] = useState("");
  // const [clientKeyword, setClientKeyword] = useState("");
  const {
    // getCompanies,
    getProducts,
    getQrCodeRequest,
    addQrCodeRequest,
    updateQrCodeRequest,
  } = useApi();
  // const {
  //   data: companies,
  //   mutate: mutateCompanies,
  //   isLoading: isCompanyLoading,
  // } = useCache("companies", () => getCompanies(1));
  const {
    data: products,
    mutate: mutateProducts,
    isLoading: isProductLoading,
  } = useCache("products", () => getProducts(1, productKeyword));
  const {
    data: product,
    isLoading,
    mutate,
  } = useCache("product", () => getQrCodeRequest(+id || 0));
  const [metaField, setMetaField] = useState<any[]>([]);

  const handleAddMetaFields = () => {
    setMetaField([
      ...metaField,
      {
        id: metaField?.length + 1,
        product: null,
        client: null,
        numberOfQrCodes: 0,
      },
    ]);
  };
  const handleRemoveMetaFields = (id: number) => {
    const newFields = metaField.filter((_, i) => i !== id - 1);
    setMetaField(newFields);
  };

  const handleMetaFieldChange = (
    id: number,
    name: "product" | "client" | "numberOfQrCodes",
    value: string | number
  ) => {
    const field = metaField[id - 1];
    if (name === "product") {
      field.product = value;
    } else if (name === "client") {
      field.client = value;
    } else {
      field.numberOfQrCodes = +value;
    }
  };

  const initialValues = useMemo(() => {
    return {
      product: product?.product_id || "",
      client: product?.company_id || "",
      numberOfQrCodes: product?.number_of_qr_codes || "",
      submit: null,
    };
  }, [product]);
  const validationSchema = Yup.object().shape({
    product: Yup.string().required("produit requis"),
    client: Yup.string().trim().notRequired(),
    numberOfQrCodes: Yup.string().trim().required("nombre de codes QR requis"),
  });

  const {
    errors,
    values,
    touched,
    handleBlur,
    handleSubmit,
    isSubmitting,
    setFieldValue,
  } = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit: async (values) => {
      try {
        const postData: any = {
          product_id: values.product,
          company_id: values.client,
          number_of_qr_codes: values.numberOfQrCodes,
          extra_data: metaField,
          // meta_data: JSON.stringify(metaField)
        };
        let response: any = null;
        if (id) {
          response = await updateQrCodeRequest({
            id: id,
            ...postData,
          });
        } else {
          response = await addQrCodeRequest({
            ...postData,
          });
        }
        if (!response.error_code || response.errors) {
          if (response.id) {
            dispatch(
              showToast({
                toast: toast,
                message: id
                  ? "qrcode request mis à jour avec succès"
                  : "qrcode request créé avec succès",
                status: toastTypes.success,
              })
            );
            if (id) {
              mutate(getQrCodeRequest(+id));
              navigate("/qrcode-requests");
            } else {
              navigate("/qrcode-requests");
            }
          }
        } else {
          dispatch(
            showToast({
              toast: toast,
              message: response.errors
                ? stringifyErrors(response.errors)
                : response.message,
              status: toastTypes.error,
            })
          );
        }
      } catch (ex) {
        console.log(ex);
        dispatch(
          showToast({
            toast: toast,
            message: "Quelque chose s'est mal passé",
            status: toastTypes.error,
          })
        );
      }
    },
  });
  return (
    <>
      {!isLoading && (
        <Flex
          gap="18px"
          bg="very_light_gray"
          flex={1}
          flexDirection="column"
          py="20px"
          px="30px"
          minH={"100vh"}
        >
          <Box>
            <Flex gap="4px" flexDirection="column">
              <Flex justifyContent="center" alignItems="center">
                <Heading size="text2xl" as="h4" fontFamily="Arimo">
                  {id
                    ? "Modifier qr code request"
                    : "Créer une commande de Codes QR"}
                </Heading>
                <Flex
                  pl="56px"
                  pr="62px"
                  gap="9px"
                  flex={1}
                  justifyContent="flex-end"
                  px={{ md: 0, base: "20px" }}
                ></Flex>
              </Flex>
            </Flex>
          </Box>
          <form
            onSubmit={handleSubmit}
            style={{
              flexDirection: "column",
              margin: "0px",
              alignItems: "center",
            }}
          >
            <Flex flexDirection="column" alignItems="center">
              <Flex
                gap="16px"
                bg="white"
                boxShadow="xl"
                w="100%"
                flexDirection="column"
                px={{ base: "20px", sm: "24px" }}
                py={{ base: "20px", sm: "24px" }}
                mx={{ md: "62px", base: "0px" }}
                borderRadius="16px"
              >
                <Flex gap="4px" flexDirection="column" alignItems="start">
                  <Text color="gray_description">Produit</Text>
                  <Flex
                    gap="4px"
                    flexDirection="column"
                    alignItems="start"
                    width={"100%"}
                  >
                    <FormControl
                      isInvalid={
                        !!errors.product && (touched.product as boolean)
                      }
                    ></FormControl>
                    <AppAsyncSelect
                      id="product"
                      name="product"
                      options={products}
                      value={values.product || initialValues.product}
                      onChange={setFieldValue}
                      handleInputChange={(inputVal) => {
                        setProductKeyword(inputVal);
                        return mutateProducts(getProducts(1, inputVal));
                      }}
                      placeholder="Sélectionnez une produit"
                      isLoading={isProductLoading}
                    />
                  </Flex>
                </Flex>
                <Flex gap="4px" flexDirection="column" alignItems="start">
                  <Text color="gray_description">
                    Quantité de Codes QR commandée
                  </Text>
                  <FormControl
                    isInvalid={
                      !!errors.numberOfQrCodes &&
                      (touched.numberOfQrCodes as boolean)
                    }
                  >
                    <AppNumberInput
                      required
                      type={`number`}
                      borderWidth="1px"
                      alignSelf="stretch"
                      borderRadius="8px"
                      id="numberOfQrCodes"
                      name="numberOfQrCodes"
                      autoComplete="numberOfQrCodes"
                      onBlur={handleBlur}
                      onChange={(val) => setFieldValue("numberOfQrCodes", val)}
                      value={
                        +values.numberOfQrCodes ||
                        +initialValues.numberOfQrCodes
                      }
                      showErrorMessage={Boolean(
                        touched.numberOfQrCodes && errors.numberOfQrCodes
                      )}
                      errorMessage={errors.numberOfQrCodes}
                    />
                  </FormControl>
                </Flex>

                {metaField?.length > 0 &&
                  metaField.map((field: any, index: number) => (
                    <div
                      key={index}
                      style={{
                        borderTop: "1px dotted gray",
                        paddingTop: "10px",
                        marginTop: "10px",
                      }}
                    >
                      <Flex
                        gap="4px"
                        flexDirection="column"
                        alignItems="start"
                        mb={"20px"}
                      >
                        <Flex
                          w={"100%"}
                          justifyContent={"space-between"}
                          alignItems={"center"}
                          gap={2}
                        >
                          <Flex justifyContent={"center"} alignItems={"center"}>
                            <Text color="gray_description">Produit</Text>
                          </Flex>
                          <IconButton
                            size="sm"
                            variant="outline"
                            colorScheme="red_origin"
                            icon={
                              <Image
                                src="images/img_delete2svgrepocom.svg"
                                alt="Delete-2 Svgrepo.com"
                              />
                            }
                            fontWeight={700}
                            borderRadius="8px"
                            aria-label="Delete"
                            height={"20px"}
                            mt={"5px"}
                            onClick={() => handleRemoveMetaFields(field.id)}
                          >
                            Supprimer
                          </IconButton>
                        </Flex>
                        <FormControl
                          isInvalid={
                            !!errors.product && (touched.product as boolean)
                          }
                        ></FormControl>
                        <AppAsyncSelect
                          id={"product" + index}
                          name={"product" + index}
                          options={products}
                          value={values.product || initialValues.product}
                          onChange={(fieldName: string, value: any) => {
                            setFieldValue(fieldName, value);
                            handleMetaFieldChange(field.id, "product", value);
                          }}
                          handleInputChange={(inputVal) => {
                            setProductKeyword(inputVal);

                            return mutateProducts(getProducts(1, inputVal));
                          }}
                          placeholder="Sélectionnez une produit"
                          isLoading={isProductLoading}
                        />
                      </Flex>
                      <Flex gap="4px" flexDirection="column" alignItems="start">
                        <Text color="gray_description">
                          Quantité de Codes QR commandée
                        </Text>
                        <FormControl
                          isInvalid={
                            !!errors.numberOfQrCodes &&
                            (touched.numberOfQrCodes as boolean)
                          }
                        >
                          <AppNumberInput
                            required
                            type={`number`}
                            borderWidth="1px"
                            alignSelf="stretch"
                            borderRadius="8px"
                            id={"numberOfQrCodes" + index}
                            name={"numberOfQrCodes" + index}
                            autoComplete="numberOfQrCodes"
                            onBlur={handleBlur}
                            onChange={(val) => {
                              handleMetaFieldChange(
                                field.id,
                                "numberOfQrCodes",
                                val
                              );
                            }}
                            showErrorMessage={Boolean(
                              touched.numberOfQrCodes && errors.numberOfQrCodes
                            )}
                            errorMessage={errors.numberOfQrCodes}
                          />
                        </FormControl>
                      </Flex>
                    </div>
                  ))}
                <Flex gap="4px" flexDirection="column" alignItems="start">
                  <Button
                    leftIcon={<AddIcon />}
                    variant="solid"
                    size="4xl"
                    color="gray_description"
                    fontFamily="Arimo"
                    fontWeight={700}
                    borderRadius="8px"
                    width={"100%"}
                    onClick={handleAddMetaFields}
                  >
                    Ajouter un champ
                  </Button>
                </Flex>
              </Flex>
              <Flex mt="24px" gap="12px">
                <Button
                  size="4xl"
                  color="gray_description"
                  fontFamily="Arimo"
                  fontWeight={700}
                  minW="106px"
                  borderRadius="8px"
                  onClick={() => window.history.back()}
                >
                  Annuler
                </Button>
                <Button
                  size="4xl"
                  colorScheme="red_origin"
                  fontFamily="Arimo"
                  fontWeight={700}
                  minW="132px"
                  borderRadius="8px"
                  type="submit"
                  isLoading={isSubmitting}
                >
                  Enregistrer
                </Button>
              </Flex>
            </Flex>
          </form>
        </Flex>
      )}
    </>
  );
}
