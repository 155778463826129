import { Box, Button, Flex, FormControl, Heading, Image, Text, useToast } from "@chakra-ui/react";
import { PhoneNumberInput } from "components/PhoneNumberInput";

import AppInput from "components/AppInput";
import { useFormik } from "formik";
import { useApi } from "hooks/useApi";
import { useCache } from "hooks/useCache";
import { useContext, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Store } from "store";
import { showToast, stringifyErrors, toastTypes } from "utils";
import * as Yup from "yup";

export default function ManageDealerPage() {
  const { id } = useParams();
  const toast = useToast();
  const navigate = useNavigate();
  const { dispatch } = useContext(Store);
  const {
    getDealer,
    addDealer,
    updateDealer,
    uploadDealerProfilePicture
  } = useApi();
  const {
    data: dealer,
    isLoading,
    mutate,
  } = useCache("dealer", () => getDealer(+id || 0));

  const [profilePictureSrc, setProfilePictureSrc] = useState(null);


  const initialValues = useMemo(() => {
    return {
      email: dealer?.email || "",
      firstName: dealer?.first_name || "",
      lastName: dealer?.last_name || "",
      country: dealer?.country_id || "",
      phoneNumber: dealer?.phone_number || "",
      profilePicture: dealer?.profile_picture || "",
      submit: null,
    };
  }, [dealer]);

  const validationSchema = Yup.object().shape({
    firstName: Yup.string().trim().required("Nom requis"),
    lastName: Yup.string().trim().required("Nom requis"),
    email: Yup.string().email().trim().required("email requis"),
    phoneNumber: Yup.string()
      .trim()
      .required("le numéro de téléphone est requis"),
    country: Yup.string().trim().required("pays requis"),
    profilePicture: Yup.mixed(),
  });

  const {
    errors,
    values,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    isSubmitting,
    setFieldValue,
  } = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit: async (values) => {
      try {
        let formData: any = new FormData();
        if (profilePictureSrc) {
          formData.append(
            "images",
            values.profilePicture,
            values.profilePicture.name
          );
        }
        formData.append("first_name", values.firstName);
        formData.append("last_name", values.lastName);
        formData.append("phone_number", values.phoneNumber);
        formData.append("country_id", values.country);
        formData.append("email", values.email);
        formData.append("is_active", true);
        if (id) {
          formData.append("id", id);
        }
        // const postData: any = {
        //   first_name: values.firstName,
        //   last_name: values.lastName,
        //   phone_number: values.phoneNumber,
        //   email: values.email,
        //   country_id: values.country,
        // };
        let response: any = null;
        if (id) {
          response = await updateDealer(formData);
        } else {
          response = await addDealer(formData);
        }
        if (!response.error_code || response.errors) {
          if (response.id) {
            if (values.profilePicture && profilePictureSrc) {
  
              const rsp = await uploadDealerProfilePicture(formData, response.id);
              if (rsp.image_id) {
                dispatch(
                  showToast({
                    toast: toast,
                    message: id
                      ? "dealer mis à jour avec succès"
                      : "dealer créé avec succès",
                    status: toastTypes.success,
                  })
                );
                if (id){
                  mutate(getDealer(+id));
                  navigate("/dealers")
                } else{
                  navigate("/dealers")
                }
              }
            }else{
              dispatch(
                showToast({
                  toast: toast,
                  message: id
                    ? "dealer mis à jour avec succès"
                    : "dealer créé avec succès",
                  status: toastTypes.success,
                })
              );
              if (id){
                mutate(getDealer(+id));
                navigate("/dealers")
              } 
            }
          }
          navigate("/dealers")
        } else {
          dispatch(
            showToast({
              toast: toast,
              message: response.errors
                ? stringifyErrors(response.errors)
                : response.message,
              status: toastTypes.error,
            })
          );
          setFieldValue("password", "");
        }
      } catch (ex) {
        console.log(ex)
        dispatch(
          showToast({
            toast: toast,
            message: "Quelque chose s'est mal passé",
            status: toastTypes.error,
          })
        );
        setFieldValue("password", "");
      }
    },
  });
  const handleFileChange = async (event, imageType) => {
    const file = event.target.files[0];
    const url = URL.createObjectURL(file);
    setProfilePictureSrc(url);
    setFieldValue("profilePicture", file)
  };
  return (
    <>
      {!isLoading && (
        <Flex
          gap="18px"
          bg="very_light_gray"
          flex={1}
          flexDirection="column"
          py="20px"
          px="30px"
        >
          <Box>
            <Flex gap="4px" flexDirection="column">
              <Flex justifyContent="center" alignItems="center">
                <Heading size="text2xl" as="h4" fontFamily="Arimo">
                  {id ? "Modifier dealer" : "Créer un dealer"}
                </Heading>
                <Flex
                  pl="56px"
                  pr="62px"
                  gap="9px"
                  flex={1}
                  justifyContent="flex-end"
                  px={{ md: 0, base: "20px" }}
                >
                </Flex>
              </Flex>
            </Flex>
          </Box>
          <form
            onSubmit={handleSubmit}
            style={{
              flexDirection: "column",
              margin: "0px",
              alignItems: "center",
            }}
          >
            <Flex flexDirection="column" alignItems="center">
              <Flex
                gap="16px"
                bg="white"
                boxShadow="xl"
                w="100%"
                flexDirection="column"
                px={{ base: "20px", sm: "24px" }}
                py={{ base: "20px", sm: "24px" }}
                mx={{ md: "62px", base: "0px" }}
                borderRadius="16px"
              >
                <Flex gap="8px" flexDirection="column" alignItems="start">
                  <Text color="gray_description" mt="16px">
                  Photo du dealer
                  </Text>
                  <AppInput
                    type="file"
                    borderWidth="1px"
                    alignSelf="stretch"
                    borderRadius="8px"
                    name="profilePicture"
                    id="profilePicture"
                    onChange={(e: any) => handleFileChange(e, "profilePicture")}
                    display="none"
                  />
                  <Flex
                    gap="16px"
                    alignSelf="stretch"
                    alignItems="center"
                    justifyContent={"flex-start"}
                    flexDirection={{ md: "row", base: "column" }}
                  >
                    <Image
                      src={profilePictureSrc || dealer?.profile_picture ? profilePictureSrc || dealer?.profile_picture : "images/image_upload_preview.jpg"}
                      alt="product Image"
                      h="100px"
                      alignSelf="center"
                      w={"100px"}
                      fit="contain"
                      borderRadius="12px"
                      onClick={() => document.getElementById('profilePicture').click()}
                    />
                    {/* <a href="/">
                      <Image
                        src="images/img_delete_svgrepo_com.svg"
                        borderRadius="50%"
                        alt="Delete Circleimage"
                        h="36px"
                        w="36px"
                      />
                    </a> */}
                  </Flex>
                </Flex>
                <Flex gap="16px" flexDirection="column">
                  
                  <Flex gap="4px" flexDirection="column" alignItems="start">
                    <Text color="gray_description">Nom</Text>
                    <FormControl
                      isInvalid={!!errors.lastName && (touched.lastName as boolean)}
                    >
                      <AppInput
                        required
                        placeholder={`Nom`}
                        borderWidth="1px"
                        alignSelf="stretch"
                        borderRadius="8px"
                        id="firstName"
                        name="lastName"
                        autoComplete="lastName"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.lastName || initialValues.lastName}
                        showErrorMessage={Boolean(touched.lastName && errors.lastName)}
                        errorMessage={errors.lastName}
                      />
                    </FormControl>
                  </Flex>
                  <Flex gap="4px" flexDirection="column" alignItems="start">
                    <Text color="gray_description">
                      Prénoms
                    </Text>
                    <FormControl
                      isInvalid={
                        !!errors.firstName && (touched.firstName as boolean)
                      }
                    >
                      <AppInput
                        required
                        placeholder={`Prénoms`}
                        borderWidth="1px"
                        alignSelf="stretch"
                        borderRadius="8px"
                        id="firstName"
                        name="firstName"
                        autoComplete="firstName"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.firstName || initialValues.firstName}
                        showErrorMessage={Boolean(
                          touched.firstName && errors.firstName
                        )}
                        errorMessage={errors.firstName}
                      />
                    </FormControl>
                  </Flex>
                </Flex>
                <Flex gap="4px" flexDirection="column" alignItems="start">
                  <Text color="gray_description">Email</Text>
                  <FormControl
                    isInvalid={!!errors.email && (touched.email as boolean)}
                  >
                    <AppInput
                      required
                      placeholder={`ronald.koffikra@gmail.com`}
                      type="email"
                      borderWidth="1px"
                      alignSelf="stretch"
                      borderRadius="8px"
                      id="email"
                      name="email"
                      autoComplete="email"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.email || initialValues.email}
                      showErrorMessage={Boolean(touched.email && errors.email)}
                      errorMessage={errors.email}
                    />
                  </FormControl>
                </Flex>
                <Flex gap="4px" flexDirection="column" alignItems="start">
                  <Text color="gray_description">Numéro de téléphone</Text>
                  <PhoneNumberInput
                    name="phoneNumber"
                    onChange={setFieldValue}
                    value={values.phoneNumber || initialValues.phoneNumber}
                    countryDialCode={dealer?.country_dial_code}
                  />
                </Flex>
              </Flex>
              <Flex mt="24px" gap="12px">
               <Button
                  size="4xl"
                  color="gray_description"
                  fontFamily="Arimo"
                  fontWeight={700}
                  minW="106px"
                  borderRadius="8px"
                  onClick={() => window.history.back()}
                >
                  Annuler
                </Button>
                <Button
                  size="4xl"
                  colorScheme="red_origin"
                  fontFamily="Arimo"
                  fontWeight={700}
                  minW="132px"
                  borderRadius="8px"
                  type="submit"
                  isLoading={isSubmitting}
                >
                  Enregistrer
                </Button>
              </Flex>
            </Flex>
          </form>
        </Flex>
      )}
    </>
  );
}
