import { ChevronDownIcon, CloseIcon, EditIcon, ViewIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Checkbox,
  DrawerBody,
  DrawerHeader,
  Flex,
  HStack,
  Heading,
  IconButton,
  Image,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Link,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  VStack,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { createColumnHelper } from "@tanstack/react-table";
import AppViewDrawer from "components/AppViewDrawer";
import AppViewListItem from "components/AppViewListItem";
import { Pagination } from "components/Pagination";
import { useApi } from "hooks/useApi";
import { useAuthentication } from "hooks/useAuthentication";
import { useCache } from "hooks/useCache";
import { useCallback, useContext, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Store } from "store";
import { showToast, toastTypes } from "utils";
import { ReactTable } from "../../components/ReactTable";

type Table3RowType = {
  id: number;
  name: string;
  rep_name: string;
  dealer_name: string;
  number_of_products: string;
  is_active?: boolean;
  actionHeader: undefined;
  logo: string;
  rep_image: string;
};

export default function ClientManagementSection() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [activeItem, setActiveItem] = useState(null);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(25);
  const toast = useToast();
  const navigate = useNavigate();
  const { dispatch } = useContext(Store);
  const {hasPermission} = useAuthentication()
  const [searchBarValue34, setSearchBarValue34] = useState("");
  const { getCompanies, updateCompany } = useApi();
  const { data: companies, mutate } = useCache("companies", () => getCompanies(page, searchBarValue34));
  const handleSearch = (event: any) => {
    const value = event.target.value
    if (event.key === 'Enter'){
      setSearchBarValue34(value);
      mutate(getCompanies(page,value))
    }else{
      setSearchBarValue34(value);
    }
  };
  const handleClearSearch = (event: any) => {
    setSearchBarValue34("");
    mutate(getCompanies(page,""))
  }
  const handleJumpToPage = (pg:number) => {
      setPage(pg)
      mutate(getCompanies(pg))
  }
  const handleSetNextPage = () => {
    if(companies.length > 0 && companies[0].num_of_pages >= page+1){
      setPage((prev:any) => prev+1)
      mutate(getCompanies(page+1))
    }
  }
  const handleSetPrevPage = () => {
    if(companies.length > 0 && page > 0){
      setPage((prev:any) => prev-1)
      mutate(getCompanies(page+1))
    }
  }

  const handleUpdateActive = useCallback(async (client:any) => {
    const postData = {...client, address:{...client?.address}, is_active: client?.is_active ? false : true}
    const response = await updateCompany(postData, false)
    if(response.id){
      dispatch(
        showToast({
          toast: toast,
          message: "Client mis à jour avec succès",
          status: toastTypes.success,
        })
      );
      mutate(getCompanies(page, searchBarValue34))
    }
  },[dispatch, getCompanies, mutate, page, searchBarValue34, toast, updateCompany])

  const handleViewItem = useCallback((item: any) => {
    setActiveItem(item);
    onOpen();
  },[onOpen,setActiveItem])
  const table3Columns = useMemo(() => {
    const table3ColumnHelper = createColumnHelper<Table3RowType>();
    return [
      table3ColumnHelper.accessor("name", {
        cell: (info) => (
          <Flex justifyContent="center">
            <Flex gap="8px" w="100%" alignItems="center" px="12px" py="0px">
              <Checkbox value="undefined" />
              <Image
                src={info.row.original.logo as unknown as string}
                alt="Nike Logo"
                h="32px"
                w="32px"
                fit="contain"
                borderRadius="10px"
              />
              <Text>{info.getValue<string>()}</Text>
            </Flex>
          </Flex>
        ),
        header: (info) => (
          <Flex p="25px">
            <Flex gap="8px" w={{ md: "42%", base: "100%" }} alignItems="center">
              <Checkbox value="undefined" />
              <Heading as="h6">Client</Heading>
            </Flex>
          </Flex>
        ),
        meta: { width: "170px" },
      }),
      table3ColumnHelper.accessor("rep_name", {
        cell: (info) => (
          <Box flex={1}>
            <Flex gap="8px" alignItems="center" px="12px" py="0px">
              <Image
                src={info.row.original.rep_image as unknown as string}
                alt="Representative Image"
                h="32px"
                w="32px"
                fit="contain"
                borderRadius="10px"
              />
              <Text>{info.getValue<string>()}</Text>
            </Flex>
          </Box>
        ),
        header: (info) => (
          <Flex flex={1} px="25px" py="14px">
            <Heading as="h6">Représentant</Heading>
          </Flex>
        ),
        meta: { width: "212px" },
      }),
      table3ColumnHelper.accessor("dealer_name", {
        cell: (info) => (
          <Box flex={1}>
            <Flex gap="8px" alignItems="center" px="12px" py="0px">
              <Text>{info.getValue<string>()}</Text>
            </Flex>
          </Box>
        ),
        header: (info) => (
          <Flex flex={1} px="25px" py="14px">
            <Heading as="h6">Dealer</Heading>
          </Flex>
        ),
        meta: { width: "190px" },
      }),
      table3ColumnHelper.accessor("number_of_products", {
        cell: (info) => (
          <Flex px={{ base: "20px", sm: "24px" }}>
            <Text>{info.getValue<string>()}</Text>
          </Flex>
        ),
        header: (info) => (
          <Flex p="14px" px="40px">
            <Heading as="h6">Nbre produits</Heading>
          </Flex>
        ),
        meta: { width: "132px" },
      }),
      table3ColumnHelper.accessor("is_active", {
        cell: (info) => (
          <Flex justifyContent="flex-start" width={"100px"}>
            <Menu>
              <MenuButton
                as={Button}
                rightIcon={<ChevronDownIcon />}
                size={"sm"}
                colorScheme={info.getValue<boolean>() ? "green_360" : "red_origin"}
                borderRadius={"4px"}
                px={"5px"}
                fontSize={"10px"}
              >
                {info.getValue<boolean>() ? "Active" : "Inactive"}
              </MenuButton>
              <MenuList>
                <MenuItem onClick={() => handleUpdateActive(info.row.original)}>
                  <span>
                    {info.getValue<boolean>() ? "Désactiver" : "Activer"}
                  </span>
                </MenuItem>
              </MenuList>
            </Menu>
          </Flex>
        ),
        header: (info) => (
          <Flex px="15px" py="14px" justifyContent={"flex-start"}>
            <Heading as="h6">État</Heading>
          </Flex>
        ),
        meta: { width: "100px" },
      }),
      table3ColumnHelper.accessor("id", {
        cell: (info) => (
          <HStack gap={"2px"}>
            {hasPermission("company", "update") &&<IconButton
              variant='outline'
              colorScheme='gray_stroke_0'
              aria-label='Edit'
              size={"sm"}
              icon={<EditIcon />}
              borderRadius={"4px"}
              onClick={() => navigate(`edit/${info.getValue()}`)}
            />}
            {hasPermission("company", "get") &&<IconButton
              variant='outline'
              colorScheme='gray_stroke_0'
              aria-label='view'
              size={"sm"}
              icon={<ViewIcon />}
              borderRadius={"4px"}
              onClick={() => handleViewItem(info.row.original)}
            />}
          </HStack>
        ),
        header: (info) => (
          <Flex px="15px" py="14px">
            <Heading as="h6">Action</Heading>
          </Flex>
        ),
        meta: { width: "100px" },
      }),
    ];
  }, [navigate, handleViewItem, hasPermission, handleUpdateActive]);
  return (
    <>
      {/* client management section */}
      <Flex
        flexDirection="column"
        alignItems="start"
        px={{ base: "20px", sm: "24px" }}
        minHeight={"100vh"}
      >
        <Flex gap="4px" alignSelf="stretch" flexDirection="column">
          <Flex
            justifyContent="center"
            alignItems="center"
            flexDirection={{ base: "column", sm: "row" }}
          >
            <Heading size="text2xl" as="h5" fontFamily="Arimo">
              Clients
            </Heading>
            <Flex
              pl="56px"
              pr="130px"
              gap="13px"
              flex={1}
              justifyContent="flex-end"
              alignItems="center"
              alignSelf={{ base: "stretch", sm: "auto" }}
              flexDirection={{ base: "column", sm: "row" }}
              px={{ md: 0, base: "20px" }}
            >
              
              {hasPermission("company", "create") && <Button
                size="xl"
                variant="outline"
                colorScheme="red_origin"
                leftIcon={
                  <Image
                    src="images/img_handshake_deal_svgrepo_com_red_origin.svg"
                    alt="Handshake-deal Svgrepo.com"
                  />
                }
                fontWeight={700}
                gap="8px"
                minW="128px"
                borderRadius="8px"
                as={Link}
                href="/clients/add"
              >
                Créer un client
              </Button>}
            </Flex>
          </Flex>
        </Flex>
        <Box
          mt="16px"
          bg="white"
          w={{ md: "100%", base: "100%" }}
          borderRadius="10px"
        >
          <Flex
            bg="white"
            justifyContent="center"
            alignItems="center"
            px="12px"
            py="16px"
            flexDirection={{ md: "row", base: "column" }}
          >
            <Flex
              flex={1}
              alignItems="center"
              alignSelf={{ md: "auto", base: "stretch" }}
              flexDirection={{ base: "column", sm: "row" }}
            >
              <InputGroup w={{ base: "100%", sm: "30%" }}>
                <InputLeftElement>
                  <Image
                    src="images/img_search_black.svg"
                    alt="Search"
                    w="16px"
                    h="14px"
                  />
                </InputLeftElement>
                <Input
                  placeholder={`Rechercher`}
                  value={searchBarValue34}
                  onKeyUp={handleSearch}
                  onChange={handleSearch}
                  gap="8px"
                  borderWidth="1px"
                  borderRadius="8px"
                />
                <InputRightElement>
                  {searchBarValue34?.length > 0 ? (
                    <CloseIcon onClick={handleClearSearch} />
                  ) : null}
                </InputRightElement>
              </InputGroup>
            </Flex>
            <Image
              src="images/img_dot_menu.svg"
              alt="Menu Image"
              h="24px"
              w={{ md: "24px", base: "100%" }}
            />
          </Flex>
          <ReactTable
            size="sm"
            headerCellProps={{ bg: "white" }}
            tableProps={{
              borderColor: "neutral.600",
              borderTopWidth: "1px",
              borderStyle: "solid",
            }}
            columns={table3Columns as any}
            data={companies}
          />
          <Pagination
            pageIndex={page}
            pageSize={pageSize}
            setNextPageIndex={handleSetNextPage}
            setPrevPageIndex={handleSetPrevPage}
            setPageSize={setPageSize}
            jumpToPage={handleJumpToPage}
            numberOfPages={companies && companies.length > 0 && companies[0]?.num_of_pages}
          />
        </Box>
      </Flex>
      <AppViewDrawer isOpen={isOpen} onOpen={onOpen} onClose={onClose}>
        <DrawerHeader>Fiche Client</DrawerHeader>

        <DrawerBody>
          <VStack alignItems={"flex-start"} gap={"15px"}>
            <HStack>
            <Button
                size="xl"
                variant="outline"
                colorScheme="green_360"
                leftIcon={
                  <EditIcon/>
                }
                fontWeight={700}
                gap="8px"
                borderRadius="8px"
                as={Link}
                href={`/clients/edit/${activeItem?.id}`}
              >
                Modifier
              </Button>
            </HStack>
            <VStack alignItems={"center"}  gap={"5px"} width={"100%"}>
            <Flex gap="8px"  padding={"1px"}>
              <Image
                src={activeItem?.logo}
                fallbackSrc="/images/dummy.png"
                alt="logo Image"
                h="100px"
                w="100px"
                fit="contain"
                borderRadius="16px"                
              />
            </Flex>
            </VStack>
            <AppViewListItem label="Nom du client" value={activeItem?.name} />
            <AppViewListItem
              label="Nom du dealer"
              value={activeItem?.dealer_name}
            />
            <AppViewListItem
              label="Nom du representative"
              value={activeItem?.rep_name}
            />
            <AppViewListItem label="Email" value={activeItem?.email} />
            <AppViewListItem
              label="Phone Number"
              value={`(${activeItem?.country_dial_code}) ${activeItem?.phone_number}`}
            />
            <AppViewListItem
              label="Nbre Produit"
              value={activeItem?.number_of_products}
            />
          </VStack>
        </DrawerBody>
      </AppViewDrawer>
    </>
  );
}
