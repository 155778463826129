import { Box, FormControl } from "@chakra-ui/react";
import { Select } from "chakra-react-select";

type AppSelectProps = {
  id: string;
  name: string;
  value: string[];
  placeholder: string;
  options: any[];
  onChange: any;
  isMulti?: boolean;
  isAsync?: boolean;
  isLoading?: boolean;
  handleInputChange?: any;
  getOptionLabel?: any;
};

const AppSelect = ({
  name,
  value,
  placeholder,
  options,
  onChange,
  id,
  handleInputChange,
  getOptionLabel,
  isMulti = false,
  isLoading = true,
}: AppSelectProps) => {
  // const customIsOptionSelected = (option, selected) => {
  //   console.log(selected);
  //   return option.id === selected.id; // Custom logic for selection
  // };
  // if (id==="city")
  // console.log(options, +value)
  return (
    <FormControl>
      <Box
        borderWidth="1px"
        alignSelf="stretch"
        borderRadius="8px"
        borderColor={"gray_stroke.0"}
      >
        {isMulti ? (
          <Select
            isLoading={isLoading}
            onInputChange={handleInputChange}
            isMulti
            name={name}
            options={options}
            placeholder={placeholder}
            closeMenuOnSelect={false}
            value={value}
            onChange={(newValue: any) => {
              onChange(name, newValue);
            }}
            id={id}
            getOptionLabel={(option: any) => option.name}
            getOptionValue={(option: any) => option.id}
          />
        ) : (
          <Select
            isLoading={isLoading}
            placeholder={placeholder}
            onInputChange={handleInputChange}
            defaultValue={options?.find((option) => option.id === +value)}
            isClearable={true}
            isSearchable={true}
            name={name}
            onChange={(newValue: any) => {
              onChange(name, newValue?.id);
            }}
            options={options}
            getOptionLabel={(option: any) => option.name}
            getOptionValue={(option: any) => option.id}
          />
        )}
      </Box>
    </FormControl>
  );
};

export default AppSelect;
