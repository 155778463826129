import { RangeDatepicker } from "chakra-dayzed-datepicker";
export default function AppDatePicker(props) {
  const { selectedDates, onDateChange } = props;
  return (
    <>
      <RangeDatepicker
        selectedDates={selectedDates}
        onDateChange={onDateChange}
        propsConfigs={{
          triggerBtnProps: {
            borderColor: "#dfe4ea",
            color: "black",
            borderRadius: "10px",
            background: "white",
            _hover: {
                background: "white",
              },
          },
          dayOfMonthBtnProps: {
            defaultBtnProps: {
              borderRadius: "10px",
              borderColor: "#e33621",
              _hover: {
                background: "#02ad02",
              },
            },
            isInRangeBtnProps: {
              background: "#e33621",
              color: "white",
            },
            selectedBtnProps: {
              background: "#e33621",
              color: "white",
              borderRadius: "10px",
            },
            todayBtnProps: {
              background: "#02ad02",
              color: "white",
            },
          },
          inputProps: {
            size: "sm",
            borderColor: "red",
          },
        }}
      />
    </>
  );
}
