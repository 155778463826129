import { Authenticated, UnAuthenticated } from "components/Guard";
import MainLayout from "components/MainLayout";
import CategoryPage from "pages/Categories";
import ClientPage from "pages/Clients";
import CodesPage from "pages/Codes";
import ComplaintPage from "pages/Complaints";
import DashboardPage from "pages/Dashboard";
import DealerPage from "pages/Dealers";
import ErrorPage from "pages/Error";
import ForgotPasswordPage from "pages/ForgotPassword";
import LoginPage from "pages/Login";
import ManageCategoryPage from "pages/ManageCategory";
import ManageClientPage from "pages/ManageClient";
import ManageDealerPage from "pages/ManageDealer";
import ManageProductPage from "pages/ManageProduct";
import ManageQrCodePage from "pages/ManageQrCode";
import ManageRolePage from "pages/ManageRole";
import ManageSubCategoryPage from "pages/ManageSubCategory";
import ManageUserPage from "pages/ManageUser";
import NotFound from "pages/NotFound";
import ProductPage from "pages/Products";
import ProfilePage from "pages/Profile";
import QrCodePage from "pages/QrCodes";
import RolePage from "pages/Roles";
import SetPasswordPage from "pages/SetPassword";
import SettingPage from "pages/Settings";
import SubCategoryPage from "pages/SubCategories";
import UserPage from "pages/Users";
import { useRoutes } from "react-router-dom";
import { ADMIN } from "utils/roles";

const ProjectRoutes = () => {
  let element = useRoutes([
    { path: "/", element: <LoginPage /> },
    { path: "*", element: <NotFound /> },
    {
      path: "error",
      element: <ErrorPage />,
    },
    // {
    //   path: "product/:token/verify",
    //   element: (
    //     <UnAuthenticated>
    //       <ProductDetailsPage />
    //     </UnAuthenticated>
    //   ),
    // },
    {
      path: "login",
      element: (
        <UnAuthenticated>
          <LoginPage />
        </UnAuthenticated>
      ),
    },
    {
      path: "forgot-password",
      element: (
        <UnAuthenticated>
          <ForgotPasswordPage />
        </UnAuthenticated>
      ),
    },
    {
      path: "reset-password/:token",
      element: (
        <UnAuthenticated>
          <SetPasswordPage />
        </UnAuthenticated>
      ),
    },
    {
      path: "dashboard",
      element: (
        <Authenticated allowRoles={[ADMIN]}>
          <DashboardPage />
        </Authenticated>
      ),
    },
    {
      path: "clients",
      element: <MainLayout/>,
      children:[
        {
          path: "",
          element: <Authenticated allowRoles={[ADMIN]}><ClientPage /></Authenticated>,
        },
        {
          path: "add",
          element: <Authenticated allowRoles={[ADMIN]}><ManageClientPage /></Authenticated>,
        },
        {
          path: "edit/:id",
          element: <Authenticated allowRoles={[ADMIN]}><ManageClientPage /></Authenticated>,
        },
      ]
    },
    {
      path: "products",
      element: <MainLayout/>,
      children:[
        {
          path: "",
          element: <Authenticated allowRoles={[ADMIN]}><ProductPage /></Authenticated>,
        },
        {
          path: "add",
          element: <Authenticated allowRoles={[ADMIN]}><ManageProductPage /></Authenticated>,
        },
        {
          path: "edit/:id",
          element: <Authenticated allowRoles={[ADMIN]}><ManageProductPage /></Authenticated>,
        },
      ]
    },
    {
      path: "dealers",
      element: <MainLayout/>,
      children:[
        {
          path: "",
          element: <Authenticated allowRoles={[ADMIN]}><DealerPage /></Authenticated>,
        },
        {
          path: "add",
          element: <Authenticated allowRoles={[ADMIN]}><ManageDealerPage /></Authenticated>,
        },
        {
          path: "edit/:id",
          element: <Authenticated allowRoles={[ADMIN]}><ManageDealerPage /></Authenticated>,
        },
      ]
    },
    {
      path: "categories",
      element: <MainLayout/>,
      children:[
        {
          path: "",
          element: <Authenticated allowRoles={[ADMIN]}><CategoryPage /></Authenticated>,
        },
        {
          path: "add",
          element: <Authenticated allowRoles={[ADMIN]}><ManageCategoryPage /></Authenticated>,
        },
        {
          path: "edit/:id",
          element: <Authenticated allowRoles={[ADMIN]}><ManageCategoryPage /></Authenticated>,
        },
      ]
    },
    {
      path: "sub-categories",
      element: <MainLayout/>,
      children:[
        {
          path: "",
          element: <Authenticated allowRoles={[ADMIN]}><SubCategoryPage /></Authenticated>,
        },
        {
          path: "add",
          element: <Authenticated allowRoles={[ADMIN]}><ManageSubCategoryPage /></Authenticated>,
        },
        {
          path: "edit/:id",
          element: <Authenticated allowRoles={[ADMIN]}><ManageSubCategoryPage /></Authenticated>,
        },
      ]
    },
    {
      path: "users",
      element: <MainLayout/>,
      children:[
        {
          path: "",
          element: <Authenticated allowRoles={[ADMIN]}><UserPage /></Authenticated>,
        },
        {
          path: "add",
          element: <Authenticated allowRoles={[ADMIN]}><ManageUserPage /></Authenticated>,
        },
        {
          path: "edit/:id",
          element: <Authenticated allowRoles={[ADMIN]}><ManageUserPage /></Authenticated>,
        },
      ]
    },
    {
      path: "roles",
      element: <MainLayout/>,
      children:[
        {
          path: "",
          element: <Authenticated allowRoles={[ADMIN]}><RolePage /></Authenticated>,
        },
        {
          path: "add",
          element: <Authenticated allowRoles={[ADMIN]}><ManageRolePage /></Authenticated>,
        },
        {
          path: "edit/:id",
          element: <Authenticated allowRoles={[ADMIN]}><ManageRolePage /></Authenticated>,
        },
      ]
    },
    {
      path: "qrcode-requests",
      element: <MainLayout/>,
      children:[
        {
          path: "",
          element: <Authenticated allowRoles={[ADMIN]}><QrCodePage /></Authenticated>,
        },
        {
          path: "add",
          element: <Authenticated allowRoles={[ADMIN]}><ManageQrCodePage /></Authenticated>,
        },
        {
          path: "edit/:id",
          element: <Authenticated allowRoles={[ADMIN]}><ManageQrCodePage /></Authenticated>,
        },
      ]
    },
    {
      path: "qrcodes/:requestId",
      element: <MainLayout/>,
      children:[
        {
          path: "",
          element: <Authenticated allowRoles={[ADMIN]}><CodesPage /></Authenticated>,
        },
      ]
    },
    {
      path: "complaints",
      element: <MainLayout/>,
      children:[
        {
          path: "",
          element: <Authenticated allowRoles={[ADMIN]}><ComplaintPage /></Authenticated>,
        },
      ]
    },
    {
      path: "settings",
      element: <MainLayout/>,
      children:[
        {
          path: "",
          element: <Authenticated allowRoles={[ADMIN]}><SettingPage /></Authenticated>,
        },
      ]
    },
    {
      path: "profile",
      element: <MainLayout/>,
      children:[
        {
          path: "update",
          element: <Authenticated allowRoles={[ADMIN]}><ProfilePage /></Authenticated>,
        },
      ]
    },
  ]);

  return element;
};

export default ProjectRoutes;
