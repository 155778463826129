import { CheckIcon, CloseIcon, DownloadIcon, EditIcon, ViewIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  DrawerBody,
  DrawerHeader,
  Flex,
  HStack,
  Heading,
  IconButton,
  Image,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Link,
  Text,
  VStack,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { createColumnHelper } from "@tanstack/react-table";
import AppViewDrawer from "components/AppViewDrawer";
import AppViewListItem from "components/AppViewListItem";
import LoadingModal from "components/LoadingModal";
import { Pagination } from "components/Pagination";
import { useApi } from "hooks/useApi";
import { useAuthentication } from "hooks/useAuthentication";
import { useCache } from "hooks/useCache";
import { useCallback, useContext, useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Store } from "store";
import { closeLoader, openLoader, showToast, toastTypes } from "utils";
import { ReactTable } from "../../components/ReactTable";

type Table3RowType = {
  id: number;
  expiry_date: string;
  manufacturing_date: string;
  product_id: boolean;
  product_name: string;
  product_image: string;
  company_id: boolean;
  status: string;
  company_name: string;
  company_image: string;
  number_of_qr_codes: string;
  created_at: string;
  processed_by: string;
  has_qr_codes: boolean;
  codes_pdf: string;
};

export default function QrCodeDashboardSection() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const loadingRef = useRef<any>(null);
  const { dispatch } = useContext(Store);
  const toast = useToast();
  const [activeItem, setActiveItem] = useState(null);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(25);
  const navigate = useNavigate();
  const { hasPermission } = useAuthentication();
  const [searchBarValue34, setSearchBarValue34] = useState("");
  const { getQrCodeRequests, generateQrCodes } = useApi();
  const { data: qrCodeRequests, mutate } = useCache("qrCodeRequests", () =>
    getQrCodeRequests(page, searchBarValue34)
  );
  const handleSearch = (event: any) => {
    const value = event.target.value;
    if (event.key === "Enter") {
      setSearchBarValue34(value);
      mutate(getQrCodeRequests(page, value));
    } else {
      setSearchBarValue34(value);
    }
  };
  const handleClearSearch = (event: any) => {
    setSearchBarValue34("");
    mutate(getQrCodeRequests(page, ""));
  };

  const handleJumpToPage = (pg: number) => {
    setPage(pg);
    mutate(getQrCodeRequests(pg));
  };
  const handleSetNextPage = () => {
    if (
      qrCodeRequests.length > 0 &&
      qrCodeRequests[0].num_of_pages >= page + 1
    ) {
      setPage((prev: any) => prev + 1);
      mutate(getQrCodeRequests(page + 1));
    }
  };
  const handleSetPrevPage = () => {
    if (qrCodeRequests.length > 0 && page > 0) {
      setPage((prev: any) => prev - 1);
      mutate(getQrCodeRequests(page + 1));
    }
  };

  const handleViewItem = useCallback(
    (item: any) => {
      setActiveItem(item);
      onOpen();
    },
    [onOpen, setActiveItem]
  );

  const handleGenerateQrCodes = useCallback(
    async (item: any) => {
      openLoader(loadingRef);
      const response = await generateQrCodes({ qr_code_request_id: item.id });
      if (response?.id) {
        dispatch(
          showToast({
            toast: toast,
            message: "Codes Qr générés avec succès",
            status: toastTypes.success,
          })
        );
      }else{
        dispatch(
          showToast({
            toast: toast,
            message: "Impossible de générer les codes Qr",
            status: toastTypes.error,
          })
        );
      }
      mutate(getQrCodeRequests(page))
      closeLoader(loadingRef)
    },
    [dispatch, generateQrCodes, getQrCodeRequests, mutate, page, toast]
  );

  const handleDownloadAll = (downloadLink:string) => {
    const pdfUrl = downloadLink;
    const link = document.createElement("a");
    link.href = pdfUrl;
    link.target = "_blank"
    link.download = "document.pdf"; // specify the filename
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
};

  const table3Columns = useMemo(() => {
    const table3ColumnHelper = createColumnHelper<Table3RowType>();
    return [
      table3ColumnHelper.accessor("company_name", {
        cell: (info) => (
          <Flex justifyContent="center">
            <Flex gap="8px" w="100%" alignItems="center" px="12px" py="0px">
              {/* <Checkbox value="true" /> */}
              <Image
                src={info.row.original.company_image as unknown as string}
                alt="Client Image"
                h="32px"
                w="32px"
                fit="contain"
                borderRadius="16px"
              />
              <Text>{info.getValue<string>()}</Text>
            </Flex>
          </Flex>
        ),
        header: (info) => (
          <Flex p="12px">
            <Flex
              gap="8px"
              w={{ md: "42%", base: "100%" }}
              alignItems="center"
              px="12px"
            >
              {/* <Checkbox value="true" /> */}
              <Heading as="h6">Client</Heading>
            </Flex>
          </Flex>
        ),
        meta: { width: "170px" },
      }),
      table3ColumnHelper.accessor("product_name", {
        cell: (info) => (
          // <Box flex={1}>
          <Flex gap="8px" alignItems="center" px="12px" py="0px">
            <Image
              src={info.row.original.product_image as unknown as string}
              alt="Client Image"
              h="32px"
              w="32px"
              fit="contain"
              borderRadius="8px"
            />
            <Text>{info.getValue<string>()}</Text>
          </Flex>
          // </Box>
        ),
        header: (info) => (
          <Flex
            gap="8px"
            w={{ md: "42%", base: "100%" }}
            alignItems="center"
            px="25px"
          >
            <Heading as="h6">Produit</Heading>
          </Flex>
        ),
        meta: { width: "212px" },
      }),
      table3ColumnHelper.accessor("number_of_qr_codes", {
        cell: (info) => (
          // <Box flex={1}>
          <Flex gap="8px" alignItems="center" px="12px" py="0px">
            <Text>{info.getValue<string>()}</Text>
          </Flex>
          // </Box>
        ),
        header: (info) => (
          <Flex
            gap="8px"
            w={{ md: "42%", base: "100%" }}
            alignItems="center"
            px="25px"
          >
            <Heading as="h6">Quantité</Heading>
          </Flex>
        ),
        meta: { width: "100px" },
      }),
      table3ColumnHelper.accessor("created_at", {
        cell: (info) => (
          <Box flex={1}>
            <Flex gap="8px" alignItems="center" px="12px" py="0px">
              <Text>{info.getValue<string>()}</Text>
            </Flex>
          </Box>
        ),
        header: (info) => (
          <Flex flex={1} px="25px" py="14px">
            <Heading as="h6">Date et heure</Heading>
          </Flex>
        ),
        meta: { width: "100px" },
      }),
      table3ColumnHelper.accessor("processed_by", {
        cell: (info) => (
          <Box flex={1}>
            <Flex gap="8px" alignItems="center" px="12px" py="0px">
              <Text>{info.getValue<string>()}</Text>
            </Flex>
          </Box>
        ),
        header: (info) => (
          <Flex flex={1} px="25px" py="14px">
            <Heading as="h6">Généré par</Heading>
          </Flex>
        ),
        meta: { width: "150px" },
      }),
      table3ColumnHelper.accessor("status", {
        cell: (info) => (
          <Box flex={1}>
            <Flex gap="8px" alignItems="center" px="12px" py="0px">
              <Text color={info.row.original.has_qr_codes ? "green_360" : "yellow"}>{info.row.original.has_qr_codes ? "Généré" : "En attente"}</Text>
            </Flex>
          </Box>
        ),
        header: (info) => (
          <Flex flex={1} px="25px" py="14px">
            <Heading as="h6">État</Heading>
          </Flex>
        ),
        meta: { width: "100px" },
      }),
      table3ColumnHelper.accessor("id", {
        cell: (info) => (
          <HStack gap={"2px"} width={"100%"} justifyContent={"flex-end"}>
            {hasPermission("qrcoderequest", "get") && (
              <IconButton
                variant="outline"
                colorScheme="gray_stroke_0"
                aria-label="view"
                size={"sm"}
                icon={<ViewIcon />}
                borderRadius={"4px"}
                onClick={() => handleViewItem(info.row.original)}
              />
            )}
            {hasPermission("qrcoderequest", "generate_qr_code") &&
              !info.row.original.has_qr_codes && (
                <IconButton
                  variant="outline"
                  colorScheme="green_360"
                  aria-label="view"
                  size={"sm"}
                  icon={<CheckIcon />}
                  borderRadius={"4px"}
                  onClick={() => handleGenerateQrCodes(info.row.original)}
                />
              )}
            {hasPermission("qrcodes", "get") &&
              info.row.original.has_qr_codes && (
                <IconButton
                  variant="outline"
                  colorScheme="gray_stroke_0"
                  aria-label="view"
                  size={"sm"}
                  icon={
                    <Image
                      src="images/qr_code_icon.png"
                      alt="qr code icon"
                      width={3}
                      height={3}
                    />
                  }
                  borderRadius={"4px"}
                  onClick={() => navigate(`/qrcodes/${info.getValue()}`)}
                />
              )}
            {hasPermission("qrcodes", "get") &&
              info.row.original.codes_pdf && (
                <IconButton
                  variant="outline"
                  colorScheme="gray_stroke_0"
                  aria-label="view"
                  size={"sm"}
                  icon={<DownloadIcon />}
                  borderRadius={"4px"}
                  onClick={() => handleDownloadAll(info.row.original?.codes_pdf)}
                />
              )}
          </HStack>
        ),
        header: (info) => (
          <Flex justifyContent={"flex-end"} px="15px" py="14px">
            <Heading as="h6">Action</Heading>
          </Flex>
        ),
        meta: { width: "100px" },
      }),
    ];
  }, [navigate, handleViewItem, hasPermission, handleGenerateQrCodes]);

  return (
    <>
      {/* client management section */}
      <Flex
        flexDirection="column"
        alignItems="start"
        px={{ base: "20px", sm: "24px" }}
      >
        <Flex gap="4px" alignSelf="stretch" flexDirection="column">
          <Flex
            justifyContent="center"
            alignItems="center"
            flexDirection={{ base: "column", sm: "row" }}
          >
            <Heading size="text2xl" as="h5" fontFamily="Arimo">
            Génération de Codes QR
            </Heading>
            <Flex
              pl="56px"
              pr="130px"
              gap="13px"
              flex={1}
              justifyContent="flex-end"
              alignItems="center"
              alignSelf={{ base: "stretch", sm: "auto" }}
              flexDirection={{ base: "column", sm: "row" }}
              px={{ md: 0, base: "20px" }}
            >
              
              {hasPermission("qrcoderequest", "create") && (
                <Button
                  size="xl"
                  variant="outline"
                  colorScheme="red_origin"
                  leftIcon={
                    <Image
                      src="images/img_handshake_deal_svgrepo_com_red_origin.svg"
                      alt="Handshake-deal Svgrepo.com"
                    />
                  }
                  fontWeight={700}
                  gap="8px"
                  minW="128px"
                  borderRadius="8px"
                  as={Link}
                  href="/qrcode-requests/add"
                >
                  Créer une commande de Codes QR
                </Button>
              )}
            </Flex>
          </Flex>
        </Flex>
        <Box
          mt="16px"
          bg="white"
          w={{ md: "100%", base: "100%" }}
          borderRadius="10px"
        >
          <Flex
            bg="white"
            justifyContent="center"
            alignItems="center"
            px="12px"
            py="16px"
            flexDirection={{ md: "row", base: "column" }}
          >
            <Flex
              flex={1}
              alignItems="center"
              alignSelf={{ md: "auto", base: "stretch" }}
              flexDirection={{ base: "column", sm: "row" }}
            >
              <InputGroup w={{ base: "100%", sm: "30%" }}>
                <InputLeftElement>
                  <Image
                    src="images/img_search_black.svg"
                    alt="Search"
                    w="16px"
                    h="14px"
                  />
                </InputLeftElement>
                <Input
                  placeholder={`Rechercher`}
                  value={searchBarValue34}
                  onChange={handleSearch}
                  gap="8px"
                  borderWidth="1px"
                  borderRadius="8px"
                />
                <InputRightElement>
                  {searchBarValue34?.length > 0 ? (
                    <CloseIcon onClick={handleClearSearch} />
                  ) : null}
                </InputRightElement>
              </InputGroup>
            </Flex>
            <Image
              src="images/img_dot_menu.svg"
              alt="Menu Image"
              h="24px"
              w={{ md: "24px", base: "100%" }}
            />
          </Flex>
          <ReactTable
            size="sm"
            headerCellProps={{ bg: "white" }}
            tableProps={{
              borderColor: "neutral.600",
              borderTopWidth: "1px",
              borderStyle: "solid",
            }}
            columns={table3Columns as any}
            data={qrCodeRequests}
          />
          <Pagination
            pageIndex={page}
            pageSize={pageSize}
            setNextPageIndex={handleSetNextPage}
            setPrevPageIndex={handleSetPrevPage}
            setPageSize={setPageSize}
            jumpToPage={handleJumpToPage}
            numberOfPages={
              qrCodeRequests &&
              qrCodeRequests?.length > 0 &&
              qrCodeRequests[0].num_of_pages
            }
          />
        </Box>
      </Flex>
      <LoadingModal ref={loadingRef} />
      <AppViewDrawer isOpen={isOpen} onOpen={onOpen} onClose={onClose}>
        <DrawerHeader>Fiche demande Code QR</DrawerHeader>

        <DrawerBody>
          <VStack alignItems={"flex-start"} gap={"15px"}>
            <HStack>
              <Button
                size="xl"
                variant="outline"
                colorScheme="green_360"
                leftIcon={<EditIcon />}
                fontWeight={700}
                gap="2px"
                borderRadius="8px"
                as={Link}
                href={`/qrCodeRequests/edit/${activeItem?.id}`}
              >
                Modifier
              </Button>
            </HStack>
            <VStack alignItems={"center"} gap={"5px"} width={"100%"}>
              <Flex gap="8px" padding={"1px"}>
                <Image
                  src={activeItem?.product_image}
                  fallbackSrc="/images/dummy.png"
                  alt="logo Image"
                  h="100px"
                  w="100px"
                  fit="contain"
                  borderRadius="16px"
                />
              </Flex>
            </VStack>
            <AppViewListItem label="Produit" value={activeItem?.product_name} />
            <AppViewListItem label="Client" value={activeItem?.company_name} />
            <AppViewListItem
              label="Quantité de Codes QR commandée"
              value={activeItem?.number_of_qr_codes}
            />
            <AppViewListItem
              label="Créé le..."
              value={activeItem?.created_at}
            />
            {/* <AppViewListItem
              label="Mis à jour le..."
              value={activeItem?.expiry_date}
            /> */}
          </VStack>
        </DrawerBody>
      </AppViewDrawer>
    </>
  );
}
