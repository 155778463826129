import { Box, Button, Container, Flex, FormControl, Image, Link, Text, useToast } from "@chakra-ui/react";

import { useAuthentication } from "hooks/useAuthentication";
import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Store } from "store";

// third party
import AppInput from "components/AppInput";
import { useFormik } from "formik";
import { showToast, stringifyErrors, toastTypes } from "utils";
import * as Yup from "yup";

export default function WelcomeSection() {
  const toast = useToast();
  const [userAuth] = useState<any>(null) 
  const { dispatch } = useContext(Store);
  const {
    authProvider: { signIn },
  } = useAuthentication();
  const navigate = useNavigate();
  const initialValues = {
    phoneNumber: userAuth?.username as string,
    password: userAuth?.password as string,
    rememberMe:null,
    submit: null,
  };
  const validationSchema = Yup.object().shape({
    phoneNumber: Yup.string().required("Le nom d'utilisateur est requis"),
    password: Yup.string().min(8).max(255).required("Le mot de passe est requis"),
    rememberMe: Yup.boolean().notRequired()
  });

  const {
    errors,
    values,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    isSubmitting,
    setFieldValue,
  } = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async ({ phoneNumber, password }) => {
      try {
        const response: any = await signIn({
          username: phoneNumber,
          password,
        });
        if (!response.error_code || response.errors) {
          navigate("/dashboard");
        } else {
          dispatch(
            showToast({
              toast: toast,
              message: response.errors
                ? stringifyErrors(response.errors)
                : response.message,
              status: toastTypes.error,
            })
          );
          setFieldValue("password", "");
        }
      } catch (ex) {
        dispatch(
          showToast({
            toast: toast,
            message: "Quelque chose s'est mal passé",
            status: toastTypes.error,
          })
        );
        setFieldValue("password", "");
      }
    },
  });
  return (
    <>
      {/* welcome section */}
      <Flex flexDirection="column" alignItems="center" mt={10}>
        <Container
          gap={{ md: "20px", base: "88px", sm: "132px" }}
          display="flex"
          flexDirection="column"
          alignItems="center"
          px={{ md: "56px", base: "20px" }}
          p={{ md: 0, base: "20px" }}
        >
          <Flex
            w={{ md: "86%", base: "100%" }}
            justifyContent="center"
            flexDirection={{ md: "row", base: "column" }}
          >
            <Box
              w={{ md: "46%", base: "100%" }}
              display={{ md: "flex", base: "none" }}
            >
              <Flex
                borderTopLeftRadius="24px"
                borderBottomLeftRadius="24px"
                gap="20px"
                bg="red_origin"
                flexDirection="column"
                px={{ base: "20px", sm: "32px" }}
              >
                <Flex
                  mt="20px"
                  gap="16px"
                  flexDirection="column"
                  alignItems="start"
                >
                  <Text size="text3xl" color="white" textAlign={"center"}>
                  Bienvenue sur ORIGIN360
                  </Text>
                  <Text
                    size="textxl"
                    color="very_light_gray"
                    w="100%"
                    lineHeight="150%"
                    textAlign={"center"}
                  >
                    Une solution de protection des marques par la vérification de l’authenticité d’articles et de documents administratifs.
                  </Text>
                </Flex>
                <Image
                  src="images/img_image_4.png"
                  alt="Decorative Image"
                  h="300px"
                  w="300px"
                  fit="contain"
                  mx={"auto"}
                />
              </Flex>
            </Box>
            <Flex
              flex={1}
              flexDirection="column"
              alignItems="start"
              alignSelf={{ md: "auto", base: "stretch" }}
            >
              <form
                onSubmit={handleSubmit}
                style={{ flex: 1, alignSelf: "stretch" }}
              >
                <Flex
                  borderTopRightRadius="24px"
                  borderBottomRightRadius="24px"
                  gap="42px"
                  bg="white"
                  w={{ md: "88%", base: "100%" }}
                  flexDirection="column"
                  alignItems="center"
                  p={{ base: "20px", sm: "22px" }}
                  height={"100%"}
                >
                  <Flex
                    gap="2px"
                    w={{ md: "90%", base: "100%" }}
                    flexDirection="column"
                  >
                    <Image
                      src="images/logo.png"
                      alt="Login Image"
                      h="112px"
                      w="112px"
                      fit="contain"
                      mx={"auto"}
                    />
                  </Flex>
                  <Flex
                    gap="8px"
                    w={{ md: "90%", base: "100%" }}
                    flexDirection="column"
                  >
                    <Flex gap="12px" flexDirection="column">
                      <FormControl
                        isInvalid={!!errors.phoneNumber && touched.phoneNumber}
                      >
                        <AppInput
                          required
                          placeholder={`ex: johon.doe@gmail.com`}
                          gap="8px"
                          borderWidth="1px"
                          alignSelf="stretch"
                          borderRadius="8px"
                          id="phoneNumber"
                          name="phoneNumber"
                          autoComplete="phoneNumber"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          defaultValue={userAuth?.username || values.phoneNumber }
                          showErrorMessage={Boolean(
                            touched.phoneNumber && errors.phoneNumber
                          )}
                          errorMessage={errors.phoneNumber}
                        />
                      </FormControl>
                      <FormControl
                        isInvalid={!!errors.password && touched.password}
                      >
                        <AppInput
                          required
                          placeholder={`*********`}
                          type="password"
                          fontFamily="Arimo"
                          gap="8px"
                          borderWidth="1px"
                          alignSelf="stretch"
                          borderRadius="8px"
                          name="password"
                          autoComplete="password"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.password}
                          showErrorMessage={Boolean(
                            touched.password && errors.password
                          )}
                          errorMessage={errors.password}
                        />
                      </FormControl>
                    </Flex>
                    <Flex justifyContent="space-between" gap="20px">
                      {/* <Checkbox
                        size="sm"
                        value="true"
                        color="gray_description"
                        fontSize="14px"
                        gap="8px"
                        // isChecked={userAuth?.username ? true : false}
                        onChange={handleChange}
                        name="rememberMe"
                        id="rememberMe"
                      >
                        Se souvenir de moi
                      </Checkbox> */}
                      <Link href="/forgot-password" size="text_regular_10" color="gray_description">
                        Mot de passe oublié ?
                      </Link>
                    </Flex>
                  </Flex>
                  <Flex
                    // mb="46px"
                    gap="12px"
                    w={{ md: "34%", base: "100%" }}
                    flexDirection="column"
                  >
                    <Button
                      size="4xl"
                      colorScheme="red_origin"
                      fontWeight={700}
                      boxShadow="md"
                      alignSelf="stretch"
                      borderRadius="8px"
                      type="submit"
                      isLoading={isSubmitting}
                    >
                      Se connecter
                    </Button>
                  </Flex>
                </Flex>
              </form>
            </Flex>
          </Flex>
          <Text
            size="texts"
            color="gray_description"
            fontFamily="Arimo"
            fontWeight={500}
          >
            Origin360. Copyrights ©️ 2024
          </Text>
        </Container>
      </Flex>
    </>
  );
}
