import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  Heading,
  Image,
  Select,
  Text,
  useToast,
} from "@chakra-ui/react";

import AppInput from "components/AppInput";
import { PhoneNumberInput } from "components/PhoneNumberInput";
import { useFormik } from "formik";
import { useApi } from "hooks/useApi";
import { useCache } from "hooks/useCache";
import { useContext, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Store } from "store";
import { showToast, stringifyErrors, toastTypes } from "utils";
import * as Yup from "yup";

export default function ManageUserPage() {
  const { id } = useParams();
  const toast = useToast();
  const navigate = useNavigate();
  const { dispatch } = useContext(Store);
  const { getUser, addUser, updateUser, getRoles } = useApi();
  const [profilePictureSrc, setProfilePictureSrc] = useState(null);
  const {
    data: user,
    isLoading,
    mutate,
  } = useCache("user", () => getUser(+id || 0));
  const { data: roles } = useCache("roles", () => getRoles());

  const initialValues = useMemo(() => {
    return {
      name: user?.name || "",
      email: user?.email || "",
      role: user?.alias,
      profilePicture: user?.profile_picture,
      phoneNumber: user?.phone_number || "",
      country: user?.country_id,
      submit: null,
    };
  }, [user]);

  const validationSchema = Yup.object().shape({
    name: Yup.string().trim().required("Nom requis").test('is-two-names', 'Nom et prenom est requis', value => {  
      return value && value.split(' ').length >= 2 && value.split(' ').every(part => part.trim() !== '');  
    }),
    email: Yup.string().email().trim().required("Email requis"),
    role: Yup.string().trim().required("Rôle requis"),
    phoneNumber: Yup.string()
      .trim()
      .required("le numéro de téléphone est requis"),
    country: Yup.string().trim().required("pays requis"),
    profilePicture: Yup.mixed(),
  });

  const {
    errors,
    values,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    isSubmitting,
    setFieldValue,
  } = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit: async (values) => {
      try {
        const names = values.name.split(' ');  
        const lastName = names[0]; // Get first name  
        const firstName = names.slice(1).join(' '); // Get other names as a string 
        let formData: any = new FormData();
        if (profilePictureSrc) {
          formData.append(
            "profile_picture",
            values.profilePicture,
            values.profilePicture.name
          );
        }
        formData.append("first_name", firstName);
        formData.append("last_name", lastName);
        formData.append("phone_number", values.phoneNumber);
        formData.append("country_id", values.country);
        formData.append("email", values.email);
        formData.append("group_name", values.role);
        if (id) {
          formData.append("id", id);
        }
        let response: any = null;
        if (id) {
          response = await updateUser(formData);
        } else {
          response = await addUser(formData);
        }
        if (!response.error_code || response.errors) {
          dispatch(
            showToast({
              toast: toast,
              message: id
                ? "Utilisateurs mis à jour avec succès"
                : "Utilisateurs créé avec succès",
              status: toastTypes.success,
            })
          );
          if (id) {
            mutate(getUser(+id));
            navigate("/users");
          } else {
            navigate(`/users`);
          }
        } else {
          dispatch(
            showToast({
              toast: toast,
              message: response.errors
                ? stringifyErrors(response.errors)
                : response.message,
              status: toastTypes.error,
            })
          );
        }
      } catch (ex) {
        dispatch(
          showToast({
            toast: toast,
            message: "Quelque chose s'est mal passé",
            status: toastTypes.error,
          })
        );
      }
    },
  });
  const handleFileChange = async (event, imageType) => {
    const file = event.target.files[0];
    const url = URL.createObjectURL(file);
    setProfilePictureSrc(url);
    setFieldValue("profilePicture", file);
  };
  return (
    <>
      {!isLoading && (
        <Flex
          gap="18px"
          bg="very_light_gray"
          flex={1}
          flexDirection="column"
          py="20px"
          px="30px"
        >
          <Box>
            <Flex gap="4px" flexDirection="column">
              <Flex justifyContent="center" alignItems="center">
                <Heading size="text2xl" as="h4" fontFamily="Arimo">
                  {id ? "Modifier utilisateurs" : "Créer utilisateurs"}
                </Heading>
                <Flex
                  pl="56px"
                  pr="62px"
                  gap="9px"
                  flex={1}
                  justifyContent="flex-end"
                  px={{ md: 0, base: "20px" }}
                ></Flex>
              </Flex>
            </Flex>
          </Box>
          <form
            onSubmit={handleSubmit}
            style={{
              flexDirection: "column",
              margin: "0px",
              alignItems: "center",
            }}
          >
            <Flex flexDirection="column" alignItems="center">
              <Flex
                gap="16px"
                bg="white"
                boxShadow="xl"
                w="100%"
                flexDirection="column"
                px={{ base: "20px", sm: "24px" }}
                py={{ base: "20px", sm: "24px" }}
                mx={{ md: "62px", base: "0px" }}
                borderRadius="16px"
              >
                <Flex gap="16px" flexDirection="column">
                  <Flex gap="8px" flexDirection="column" alignItems="start">
                    <Text color="gray_description" mt="16px">
                    Photo du dealer
                    </Text>
                    <AppInput
                      type="file"
                      borderWidth="1px"
                      alignSelf="stretch"
                      borderRadius="8px"
                      name="profilePicture"
                      id="profilePicture"
                      onChange={(e: any) =>
                        handleFileChange(e, "profilePicture")
                      }
                      display="none"
                    />
                    <Flex
                      gap="16px"
                      alignSelf="stretch"
                      alignItems="center"
                      justifyContent={"flex-start"}
                      flexDirection={{ md: "row", base: "column" }}
                    >
                      <Image
                        src={
                          profilePictureSrc || user?.profile_picture
                            ? profilePictureSrc || user?.profile_picture
                            : "images/image_upload_preview.jpg"
                        }
                        alt="User Image"
                        h="100px"
                        alignSelf="center"
                        w={"100px"}
                        fit="contain"
                        borderRadius="12px"
                        onClick={() =>
                          document.getElementById("profilePicture").click()
                        }
                      />
                    </Flex>
                  </Flex>
                  <Flex gap="4px" flexDirection="column" alignItems="start">
                    <Text color="gray_description">Nom</Text>
                    <FormControl
                      isInvalid={!!errors.name && (touched.name as boolean)}
                    >
                      <AppInput
                        required
                        placeholder={`Nom`}
                        borderWidth="1px"
                        alignSelf="stretch"
                        borderRadius="8px"
                        id="name"
                        name="name"
                        autoComplete="name"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.name || initialValues.name}
                        showErrorMessage={Boolean(touched.name && errors.name)}
                        errorMessage={errors.name}
                      />
                    </FormControl>
                  </Flex>
                  <Flex gap="4px" flexDirection="column" alignItems="start">
                    <Text color="gray_description">Email</Text>
                    <FormControl
                      isInvalid={!!errors.email && (touched.email as boolean)}
                    >
                      <AppInput
                        required
                        placeholder={`Email`}
                        type="email"
                        borderWidth="1px"
                        alignSelf="stretch"
                        borderRadius="8px"
                        id="email"
                        name="email"
                        autoComplete="email"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.email || initialValues.email}
                        showErrorMessage={Boolean(
                          touched.email && errors.email
                        )}
                        errorMessage={errors.email}
                      />
                    </FormControl>
                  </Flex>
                  <Flex gap="4px" flexDirection="column" alignItems="start">
                    <Text color="gray_description">Rôle</Text>
                    <Flex
                      gap="4px"
                      flexDirection="column"
                      alignItems="start"
                      width={"100%"}
                    >
                      <FormControl
                        isInvalid={!!errors.name && (touched.name as boolean)}
                      >
                        <Select
                          required
                          name="role"
                          id="role"
                          borderRadius="8px"
                          borderWidth="1px"
                          alignSelf="stretch"
                          onChange={handleChange}
                          value={values.role}
                        >
                          <option value={""}>Sélectionnez un rôle</option>
                          {roles?.map((role: any) => (
                            <option key={role.group_name} value={role.alias}>
                              {role.alias}
                            </option>
                          ))}
                        </Select>
                        {Boolean(touched.role && errors.role) && (
                          <FormErrorMessage style={{ color: "red" }}>
                            {errors.role}
                          </FormErrorMessage>
                        )}
                      </FormControl>
                    </Flex>
                  </Flex>
                  <Flex gap="4px" flexDirection="column" alignItems="start">
                    <Text color="gray_description">Numero de telephone</Text>
                    <PhoneNumberInput
                      name="phoneNumber"
                      onChange={setFieldValue}
                      value={values.phoneNumber || initialValues.phoneNumber}
                      countryDialCode={user?.country_dial_code}
                    />
                  </Flex>
                </Flex>
              </Flex>
              <Flex mt="24px" gap="12px">
               <Button
                  size="4xl"
                  color="gray_description"
                  fontFamily="Arimo"
                  fontWeight={700}
                  minW="106px"
                  borderRadius="8px"
                  onClick={() => window.history.back()}
                >
                  Annuler
                </Button>
                <Button
                  size="4xl"
                  colorScheme="red_origin"
                  fontFamily="Arimo"
                  fontWeight={700}
                  minW="132px"
                  borderRadius="8px"
                  type="submit"
                  isLoading={isSubmitting}
                >
                  Enregistrer
                </Button>
              </Flex>
            </Flex>
          </form>
        </Flex>
      )}
    </>
  );
}
