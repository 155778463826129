import { ChevronDownIcon, ChevronUpIcon } from "@chakra-ui/icons";
import {
  Box,
  Input,
  InputGroup,
  InputLeftElement,
  Text,
  useDisclosure,
  useOutsideClick
} from "@chakra-ui/react";
import { useApi } from "hooks/useApi";
import { useEffect, useRef, useState } from "react";
import { countries } from "utils/countries";
import { Country, SearchOnList } from "./SearchOnList";

export type PhoneNumberInputProps = {
  value: string;
  onChange: any;
  name:string;
  countryDialCode?:string;
};

export const PhoneNumberInput = ({ onChange, value, name, countryDialCode }: PhoneNumberInputProps) => {
  const ref = useRef(null);
  const [number, setNumber] = useState("");
  const [country, setCountry] = useState("+225");
  const [countryFlag, setCountryFlag] = useState(`🇨🇮`);
  const { isOpen, onToggle, onClose } = useDisclosure();
  const {
    countries:cntries,
  } = useApi();

  useOutsideClick({
    ref: ref,
    handler: () => onClose()
  });

  
  useEffect(() => {
    if (country !== "" || number !== "") {
      onChange(name,`${number || value}`);
      onChange("country",cntries?.find((count:any) => count?.dial_code === country)?.id)
    }
    if(countryDialCode){
      setCountryFlag(countries?.find((count:any) => count?.dial_code === countryDialCode)?.flag)
      setCountry(countryDialCode)
    }
    // eslint-disable-next-line
  }, [country, number, onChange, countryDialCode,cntries,name]);
  
  const onCountryChange = (item: Country) => {
    setCountry(item?.dial_code);
    setCountryFlag(item?.flag);
    onChange(name,number);
    onChange("country",cntries?.find((count:any) => count?.dial_code === country)?.id)
    onClose();
  };

  const onPhoneNumberChange = (event: any) => {
    const value = event.target.value;
    setNumber(value);
    onChange(number);
    onChange("country",cntries?.find((count:any) => count?.dial_code === country)?.id)
  };

  return (
    <>
      <Box as="section" ref={ref} position="relative" width={"100%"}>
        <InputGroup>
          <InputLeftElement width="5em" cursor="pointer" onClick={onToggle}>
            <Text as="span" mr={3}>
              {countryFlag}
            </Text>
            {isOpen ? (
              <ChevronUpIcon boxSize={6} color="gray.500" />
            ) : (
              <ChevronDownIcon boxSize={6} color="gray.500" />
            )}
          </InputLeftElement>
          <Input
            pl="5em"
            type="tel"
            value={number || value}
            placeholder="Entrer votre numéro de téléphone"
            onChange={onPhoneNumberChange}
            borderWidth="1px"
            alignSelf="stretch"
            borderRadius="8px"
          />
        </InputGroup>

        {isOpen ? (
          <SearchOnList data={countries} onChange={onCountryChange} />
        ) : null}
      </Box>
    </>
  );
};
  