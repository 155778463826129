import { Box, FormControl } from "@chakra-ui/react";
import { AsyncSelect } from "chakra-react-select";

type AppSelectProps = {
  id: string;
  name: string;
  value: string[];
  placeholder: string;
  options?: any[];
  onChange: any;
  isMulti?: boolean;
  isAsync?: boolean;
  isLoading?: boolean;
  handleInputChange?: any;
};

const AppAsyncSelect = ({
  name,
  value,
  placeholder,
  onChange,
  id,
  handleInputChange,
  isMulti = false,
  isLoading = true,
}: AppSelectProps) => {
  const filterData = (inputValue: string) => {
    return handleInputChange(inputValue);
  };

  const promiseOptions: any = (inputValue) =>
    new Promise((resolve) => {
      setTimeout(() => {
        resolve(filterData(inputValue));
      }, 1000);
    });
  return (
    <FormControl>
      <Box
        borderWidth="1px"
        alignSelf="stretch"
        borderRadius="8px"
        borderColor={"gray_stroke.0"}
      >
        {isMulti ? (
          <AsyncSelect
            isMulti
            id={id}
            name={name}
            placeholder={placeholder}
            cacheOptions
            defaultOptions
            defaultValue={value}
            onChange={(newValue: any) => {
              onChange(name, newValue);
            }}
            loadOptions={promiseOptions}
            getOptionLabel={(option: any) => option.name}
            getOptionValue={(option: any) => option.id}
          />
        ) : (
          <AsyncSelect
            id={id}
            name={name}
            placeholder={placeholder}
            cacheOptions
            defaultOptions
            defaultValue={value}
            onChange={(newValue: any) => {
                console.log(newValue)
              onChange(name, newValue?.id);
            }}
            loadOptions={promiseOptions}
            getOptionLabel={(option: any) => option.name}
            getOptionValue={(option: any) => option.id}
            isLoading={isLoading}
            isClearable={true}
          />
        )}
      </Box>
    </FormControl>
  );
};

export default AppAsyncSelect;
