import { Box, Button, Flex, Image } from "@chakra-ui/react";

export default function QRCodeSection(props:any) {
  const {handleGenerateReportCSV} = props;
  return (
    <>
      {/* q r code section */}
      <Box>
        <Flex
          h={{ md: "132px", base: "auto" }}
          bgImage="url(/images/img_frame_1000005236_132x1148.png)"
          bgSize="cover"
          bgRepeat="no-repeat"
          justifyContent="space-between"
          alignItems="center"
          // gap="20px"
          px={{ base: "20px", sm: "32px" }}
          borderRadius="16px"
        >
          <Image
            src="images/img_qrcode_ai_4ea58_104x138.png"
            alt="Qr Code Image"
            h="104px"
            mt="28px"
            ml={{ md: "14px", base: "0px" }}
            borderTopRightRadius="16px"
            borderTopLeftRadius="16px"
            alignSelf="end"
            w="12%"
            fit="contain"
          />
          <Button
            size="2xl"
            colorScheme="red_origin"
            fontFamily="Arimo"
            textTransform="capitalize"
            fontWeight={600}
            minW="98px"
            borderRadius="8px"
            onClick={handleGenerateReportCSV}
          >
            Générer un rapport
          </Button>
        </Flex>
      </Box>
    </>
  );
}
