import { AddIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  Heading,
  IconButton,
  Image,
  Text,
  Textarea,
  useToast,
} from "@chakra-ui/react";

import { AppDropdownSelect } from "components/AppDropdownSelect";
import AppInput from "components/AppInput";
import { useFormik } from "formik";
import { useApi } from "hooks/useApi";
import { useAuthentication } from "hooks/useAuthentication";
import { useCache } from "hooks/useCache";
import { useContext, useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Store } from "store";
import { showToast, stringifyErrors, toastTypes } from "utils";
import * as Yup from "yup";

export default function ManageProductPage() {
  const { id } = useParams();
  const toast = useToast();
  const navigate = useNavigate();
  const { dispatch } = useContext(Store);
  const { user } = useAuthentication();
  const {
    getCompanies,
    getProduct,
    addProduct,
    updateProduct,
    getSubCategories,
    uploadProductImage,
  } = useApi();
  const { data: companies } = useCache("companies", () => getCompanies(1));
  const { data: subCategories } = useCache("subCategories", () =>
    getSubCategories()
  );
  const {
    data: product,
    isLoading,
    mutate,
  } = useCache("product", () => getProduct(+id || 0));
  const [productImageSrc, setProductImageSrc] = useState(
    product?.image || null
  );
  const [metaField, setMetaField] = useState<any[]>([]);

  useEffect(() => {
    if (product?.meta_data) setMetaField(JSON.parse(product?.meta_data));
  }, [product, setMetaField]);

  const handleAddMetaFields = () => {
    setMetaField([
      ...metaField,
      { id: metaField.length + 1, label: null, fieldValue: null },
    ]);
  };
  const handleRemoveMetaFields = (id: number) => {
    const newFields = metaField.filter((_, i) => i !== id - 1);
    setMetaField(newFields);
  };

  const handleMetaFieldChange = (
    id: number,
    name: "label" | "input",
    value: string
  ) => {
    // console.log(metaField, value);
    // const field = metaField[id - 1];
    if (name === "label") {
      // field.label = value;
      metaField[id - 1] = { ...metaField[id - 1], label: value };
    } else {
      // field.fieldValue = value;
      metaField[id - 1] = { ...metaField[id - 1], fieldValue: value };
    }
    setMetaField(metaField);
  };

  const initialValues = useMemo(() => {
    return {
      subCategory: product?.subcategory_id || "",
      description: product?.description || "",
      name: product?.name || "",
      company: product?.company_id || user?.user.company_id || "",
      image: null,
      submit: null,
    };
  }, [product, user?.user.company_id]);
  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Nom requis"),
    subCategory: Yup.string().trim().required("sous-catégorie requis"),
    description: Yup.string().trim().required("Une description est requise"),
    image: Yup.mixed().notRequired(),
  });

  const {
    errors,
    values,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    isSubmitting,
    setFieldValue,
  } = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit: async (values) => {
      try {
        const postData: any = {
          name: values.name,
          subcategory_id: values.subCategory,
          description: values.description,
          company_id: values.company,
          meta_data: JSON.stringify(metaField),
        };
        let response: any = null;
        if (id) {
          response = await updateProduct({
            id: id,
            ...postData,
          });
        } else {
          response = await addProduct({
            ...postData,
          });
        }
        if (!response.error_code || response.errors) {
          if (response.id) {
            if (values.image) {
              let formData: any = new FormData();
              formData.append("images", values.image, values.image.name);

              const rsp = await uploadProductImage(formData, response.id);
              if (rsp.image_id) {
                dispatch(
                  showToast({
                    toast: toast,
                    message: id
                      ? "produit mis à jour avec succès"
                      : "produit créé avec succès",
                    status: toastTypes.success,
                  })
                );
                if (id) {
                  mutate(getProduct(+id));
                  navigate("/products");
                } else {
                  navigate("/products");
                }
              }
            } else {
              dispatch(
                showToast({
                  toast: toast,
                  message: id
                    ? "produit mis à jour avec succès"
                    : "produit créé avec succès",
                  status: toastTypes.success,
                })
              );
              if (id) mutate(getProduct(+id));
              navigate("/products");
            }
          }
        } else {
          dispatch(
            showToast({
              toast: toast,
              message: response.errors
                ? stringifyErrors(response.errors)
                : response.message,
              status: toastTypes.error,
            })
          );
        }
      } catch (ex) {
        console.log(ex);
        dispatch(
          showToast({
            toast: toast,
            message: "Quelque chose s'est mal passé",
            status: toastTypes.error,
          })
        );
      }
    },
  });
  const handleFileChange = async (event, imageType) => {
    const file = event.target.files[0];
    const url = URL.createObjectURL(file);
    setProductImageSrc(url);
    setFieldValue("image", file);
  };
  return (
    <>
      {!isLoading && (
        <Flex
          gap="18px"
          bg="very_light_gray"
          flex={1}
          flexDirection="column"
          py="20px"
          px="30px"
        >
          <Box>
            <Flex gap="4px" flexDirection="column">
              <Flex justifyContent="center" alignItems="center">
                <Heading size="text2xl" as="h4" fontFamily="Arimo">
                  {id ? "Modifier produit" : "Créer un produit"}
                </Heading>
                <Flex
                  pl="56px"
                  pr="62px"
                  gap="9px"
                  flex={1}
                  justifyContent="flex-end"
                  px={{ md: 0, base: "20px" }}
                ></Flex>
              </Flex>
            </Flex>
          </Box>
          <form
            onSubmit={handleSubmit}
            style={{
              flexDirection: "column",
              margin: "0px",
              alignItems: "center",
            }}
          >
            <Flex flexDirection="column" alignItems="center">
              <Flex
                gap="16px"
                bg="white"
                boxShadow="xl"
                w="100%"
                flexDirection="column"
                px={{ base: "20px", sm: "24px" }}
                py={{ base: "20px", sm: "24px" }}
                mx={{ md: "62px", base: "0px" }}
                borderRadius="16px"
              >
                <Flex gap="8px" flexDirection="column" alignItems="start">
                  <Text color="gray_description" mt="16px">
                    Image du produit
                  </Text>
                  <AppInput
                    type="file"
                    borderWidth="1px"
                    alignSelf="stretch"
                    borderRadius="8px"
                    name="banner"
                    id="banner"
                    onChange={(e: any) => handleFileChange(e, "banner")}
                    display="none"
                  />
                  <Flex
                    gap="16px"
                    alignSelf="stretch"
                    alignItems="center"
                    justifyContent={"flex-start"}
                    flexDirection={{ md: "row", base: "column" }}
                  >
                    <Image
                      src={
                        productImageSrc || product?.image
                          ? productImageSrc || product?.image
                          : "images/product_image_upload_preview.jpeg"
                      }
                      alt="product Image"
                      h="200px"
                      alignSelf="center"
                      w={"300px"}
                      fit="contain"
                      borderRadius="12px"
                      onClick={() => document.getElementById("banner").click()}
                    />
                    {/* <a href="/">
                      <Image
                        src="images/img_delete_svgrepo_com.svg"
                        borderRadius="50%"
                        alt="Delete Circleimage"
                        h="36px"
                        w="36px"
                      />
                    </a> */}
                  </Flex>
                </Flex>
                <Flex gap="16px" flexDirection="column">
                  <Flex gap="4px" flexDirection="column" alignItems="start">
                    <Text color="gray_description">Nom du produit</Text>
                    <FormControl
                      isInvalid={!!errors.name && (touched.name as boolean)}
                    >
                      <AppInput
                        required
                        placeholder={`Nike`}
                        borderWidth="1px"
                        alignSelf="stretch"
                        borderRadius="8px"
                        id="name"
                        name="name"
                        autoComplete="name"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.name || initialValues.name}
                        showErrorMessage={Boolean(touched.name && errors.name)}
                        errorMessage={errors.name}
                      />
                    </FormControl>
                  </Flex>
                </Flex>
                <Flex gap="4px" flexDirection="column" alignItems="start">
                  <Text color="gray_description">Catégorie</Text>
                  <Flex
                    gap="4px"
                    flexDirection="column"
                    alignItems="start"
                    width={"100%"}
                  >
                    <FormControl
                      isInvalid={
                        !!errors.subCategory && (touched.subCategory as boolean)
                      }
                    ></FormControl>
                    <AppDropdownSelect
                      name="subCategory"
                      data={subCategories}
                      value={values.subCategory || initialValues.subCategory}
                      onChange={setFieldValue}
                      placeholder="Sélectionnez une catégorie"
                    />
                  </Flex>
                </Flex>
                <Flex gap="4px" flexDirection="column" alignItems="start">
                  <Text color="gray_description">Description</Text>
                  <FormControl
                    isInvalid={
                      !!errors.description && (touched.description as boolean)
                    }
                  >
                    <Textarea
                      required
                      borderWidth="1px"
                      alignSelf="stretch"
                      borderRadius="8px"
                      id="description"
                      name="description"
                      autoComplete="description"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.description || initialValues.description}
                      // showErrorMessage={Boolean(touched.description && errors.description)}
                      // errorMessage={errors.description}
                    />
                    <FormErrorMessage style={{ color: "red" }}>
                      {errors.description}
                    </FormErrorMessage>
                  </FormControl>
                </Flex>

                {metaField &&
                  metaField?.map((field: any, index: number) => (
                    <Flex
                      gap="4px"
                      flexDirection="column"
                      alignItems="start"
                      key={index}
                      sx={{
                        borderTop: "1px dotted gray",
                        paddingTop: "10px",
                        marginTop: "10px",
                      }}
                    >
                      <Flex
                        justifyContent={"center"}
                        alignItems={"center"}
                        gap={2}
                      >
                        {/* {field.label ? (
                          <Text color="gray_description">{field.label}</Text>
                        ) : ( */}
                        <AppInput
                          required
                          type="text"
                          placeholder="Nom du champ...."
                          height={"25px"}
                          width={"200px"}
                          borderWidth="1px"
                          alignSelf="stretch"
                          borderRadius="8px"
                          onChange={(e: any) => {
                            handleMetaFieldChange(
                              field.id,
                              "label",
                              e.target.value
                            );
                          }}
                          defaultValue={field.label}
                        />
                        <IconButton
                          size="sm"
                          variant="outline"
                          colorScheme="red_origin"
                          icon={
                            <Image
                              src="images/img_delete2svgrepocom.svg"
                              alt="Delete-2 Svgrepo.com"
                            />
                          }
                          fontWeight={700}
                          borderRadius="8px"
                          aria-label="Delete"
                          height={"23px"}
                          mt={"5px"}
                          onClick={() => handleRemoveMetaFields(field.id)}
                        >
                          Supprimer
                        </IconButton>
                        {/* )} */}
                      </Flex>
                      <AppInput
                        required
                        placeholder={"Entrez une donnée pour ce champ..."}
                        type="text"
                        borderWidth="1px"
                        alignSelf="stretch"
                        borderRadius="8px"
                        onChange={(e: any) =>
                          handleMetaFieldChange(
                            field.id,
                            "input",
                            e.target.value
                          )
                        }
                        defaultValue={field.fieldValue}
                      />
                    </Flex>
                  ))}
                {!user?.user.company_id && (
                  <Flex gap="4px" flexDirection="column" alignItems="start">
                    <Text color="gray_description">Client</Text>
                    <Flex
                      gap="4px"
                      flexDirection="column"
                      alignItems="start"
                      width={"100%"}
                    >
                      <AppDropdownSelect
                        name="company"
                        data={companies}
                        value={values.company || initialValues.company}
                        onChange={setFieldValue}
                        placeholder="Sélectionnez une client"
                      />
                    </Flex>
                  </Flex>
                )}
                <Flex gap="4px" flexDirection="column" alignItems="start">
                  <Button
                    leftIcon={<AddIcon />}
                    variant="solid"
                    size="4xl"
                    color="gray_description"
                    fontFamily="Arimo"
                    fontWeight={700}
                    borderRadius="8px"
                    width={"100%"}
                    onClick={handleAddMetaFields}
                  >
                    Ajouter un champ
                  </Button>
                </Flex>
              </Flex>
              <Flex mt="24px" gap="12px">
                <Button
                  size="4xl"
                  color="gray_description"
                  fontFamily="Arimo"
                  fontWeight={700}
                  minW="106px"
                  borderRadius="8px"
                  onClick={() => window.history.back()}
                >
                  Annuler
                </Button>
                <Button
                  size="4xl"
                  colorScheme="red_origin"
                  fontFamily="Arimo"
                  fontWeight={700}
                  minW="132px"
                  borderRadius="8px"
                  type="submit"
                  isLoading={isSubmitting}
                >
                  Enregistrer
                </Button>
              </Flex>
            </Flex>
          </form>
        </Flex>
      )}
    </>
  );
}
