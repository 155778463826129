// Chakra imports
// Chakra imports
import {
  Flex,
  Stat,
  StatLabel,
  StatNumber,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
// Custom components
import { colors } from "theme/foundations";
import Card from ".";

export default function MiniStatistics(props:any) {
  const { startContent, endContent, name, growth, value, onClick } = props;
  const textColor = useColorModeValue("secondaryGray.900", "white");

  return (
    <Card py='15px' onClick={onClick} sx={{cursor:"pointer"}}>
      <Flex
        my='auto'
        h='100%'
        backgroundColor={"white"}
        paddingY={"20px"}
        paddingX={"10px"}
        borderRadius={"15px"}
        align={{ base: "center", xl: "start" }}
        justify={{ base: "center", xl: "center" }}>
        {startContent}

        <Stat my='auto' ms={startContent ? "18px" : "0px"}>
          <StatLabel
            lineHeight='100%'
            color={'gray_description'}
            mb={2}
            fontSize={{
              base: "sm",
            }}>
            {name}
          </StatLabel>
          <StatNumber
            color={textColor}
            fontSize={{
              base: "2xl",
            }}>
            {value}
          </StatNumber>
          {growth ? (
            <Flex align='center'>
              <Text color='green.500' fontSize='xs' fontWeight='700' me='5px'>
                {growth}
              </Text>
              <Text color={colors.gray[100]} fontSize='xs' fontWeight='400'>
                since last month
              </Text>
            </Flex>
          ) : null}
        </Stat>
        <Flex ms='auto' w='max-content'>
          {endContent}
        </Flex>
      </Flex>
    </Card>
  );
}
  