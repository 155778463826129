import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuthentication } from "./useAuthentication";

import axios from "axios";
import { useCache } from "./useCache";

const API_BASE_URL = process.env.REACT_APP_SUCCOTH_API_BASE_URL;
const ACCESS_TOKEN = process.env.REACT_APP_SUCCOTH_API_ACCESS_TOKEN;

export function useApi() {
  const navigate = useNavigate();
  const {
    user,
    authProvider:{signOut}
  } = useAuthentication();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const createAccessHeader: any = useCallback(
    async (apiKey?: string, files=false) => {
      try {
        const token = user ? user?.auth.access_token : ACCESS_TOKEN;
        return {
          "Content-Type": files ? "multipart/form-data" :  "application/json",
          Authorization: `Bearer ${token}`,
        };
      } catch (error) {
        navigate("/error");
        console.log(error);
        return null;
      }
    },
    [navigate, user]
  );

  type sendRequestProps = {
    method: string;
    url: string;
    data?: any;
    files?:boolean
  };

  const sendRequest = useCallback(
    async ({ method, url, data, files }: sendRequestProps) => {
      return axios({
        url: url,
        method: method,
        headers: await createAccessHeader(null, files),
        data: data,
      })
        .then((response) => {
          return response.data;
        })
        .catch((error: any) => {
          console.log(user)
          if (
            error?.response?.status === 500 ||
            error.message === "Network Error" ||
            error.code === "ERR_BAD_RESPONSE"
          ) {
            navigate("/error");
            return;
          }
          if (error?.response?.status === 401) {
            // show permission screen page instead
            signOut()
            // navigate("/error");
            return;
          }
          if (error.code === "ERR_CANCELED") {
            console.log("Request timed out");
          } else {
            return error.response.data;
          }
        });
    },
    [createAccessHeader, navigate, user, signOut]
  );

    // Complaints
    const getComplaints = useCallback(async (page:number=1,keyword:string="") => {
      try {
        const result = await sendRequest({
          method: "get",
          url: `${API_BASE_URL}/complaints/?page=${page}&keyword=${keyword}`,
        });
        setIsLoading(false);
        // setCountries(await result);
        return result;
      } catch (err) {
        setIsLoading(false);
        console.log("ERRORRRRR", err);
        return null;
      }
    }, [sendRequest]);

  // Areas
  const getAreas = useCallback(async (page:number =1, keyword:string="") => {
    try {
      const result = await sendRequest({
        method: "get",
        url: `${API_BASE_URL}/areas/?page=${page}&keyword=${keyword}`,
      });
      setIsLoading(false);
      // setCountries(await result);
      return result;
    } catch (err) {
      setIsLoading(false);
      console.log("ERRORRRRR", err);
      return null;
    }
  }, [sendRequest]);

  const getArea = useCallback(async (id) => {
    try {
      const result = await sendRequest({
        method: "get",
        url: `${API_BASE_URL}/areas/${id}`,
      });
      setIsLoading(false);
      // setCountries(await result);
      return result;
    } catch (err) {
      setIsLoading(false);
      console.log("ERRORRRRR", err);
      return null;
    }
  }, [sendRequest]);

  const addArea = useCallback(
    async (data: any) => {
      try {
        const result = await sendRequest({
          method: "post",
          url: `${API_BASE_URL}/areas/`,
          data,
        });
        setIsLoading(false);
        return result;
      } catch (err) {
        setIsLoading(false);
        console.log("ERRORRRRR", err);
        return null;
      }
    },
    [sendRequest]
  );
  const updateArea = useCallback(
    async (data: any) => {
      try {
        const result = await sendRequest({
          method: "put",
          url: `${API_BASE_URL}/areas/${data.id}/`,
          data,
        });
        setIsLoading(false);
        return result;
      } catch (err) {
        setIsLoading(false);
        console.log("ERRORRRRR", err);
        return null;
      }
    },
    [sendRequest]
  );

  // Cities
  const getCities = useCallback(async (countryId?:number, page:number=1, keyword:string="") => {
    try {
      const result = await sendRequest({
        method: "get",
        url: `${API_BASE_URL}/cities/?page=${page}&countryId=${countryId || 0}&keyword=${keyword}`,
      });
      setIsLoading(false);
      // setCountries(await result);
      return result;
    } catch (err) {
      setIsLoading(false);
      console.log("ERRORRRRR", err);
      return null;
    }
  }, [sendRequest]);

  const getCity = useCallback(async (id:number) => {
    try {
      const result = await sendRequest({
        method: "get",
        url: `${API_BASE_URL}/cities/${id}`,
      });
      setIsLoading(false);
      // setCountries(await result);
      return result;
    } catch (err) {
      setIsLoading(false);
      console.log("ERRORRRRR", err);
      return null;
    }
  }, [sendRequest]);

  const addCity = useCallback(
    async (data: any) => {
      try {
        const result = await sendRequest({
          method: "post",
          url: `${API_BASE_URL}/cities/`,
          data,
        });
        setIsLoading(false);
        return result;
      } catch (err) {
        setIsLoading(false);
        console.log("ERRORRRRR", err);
        return null;
      }
    },
    [sendRequest]
  );
  const updateCity = useCallback(
    async (data: any) => {
      try {
        const result = await sendRequest({
          method: "put",
          url: `${API_BASE_URL}/cities/${data.id}/`,
          data,
        });
        setIsLoading(false);
        return result;
      } catch (err) {
        setIsLoading(false);
        console.log("ERRORRRRR", err);
        return null;
      }
    },
    [sendRequest]
  );

  // Countries
  const getCountries = useCallback(async (page:number=1,keyword:string="") => {
    try {
      const result = await sendRequest({
        method: "get",
        url: `${API_BASE_URL}/countries/?page=${page}&keyword=${keyword}`,
      });
      setIsLoading(false);
      // setCountries(await result);
      return result;
    } catch (err) {
      setIsLoading(false);
      console.log("ERRORRRRR", err);
      return null;
    }
  }, [sendRequest]);

  const getCountry = useCallback(async (id:number) => {
    try {
      const result = await sendRequest({
        method: "get",
        url: `${API_BASE_URL}/countries/${id}`,
      });
      setIsLoading(false);
      // setCountries(await result);
      return result;
    } catch (err) {
      setIsLoading(false);
      console.log("ERRORRRRR", err);
      return null;
    }
  }, [sendRequest]);

  const addCountry = useCallback(
    async (data: any) => {
      try {
        const result = await sendRequest({
          method: "post",
          url: `${API_BASE_URL}/countries/`,
          data,
        });
        setIsLoading(false);
        return result;
      } catch (err) {
        setIsLoading(false);
        console.log("ERRORRRRR", err);
        return null;
      }
    },
    [sendRequest]
  );
  const updateCountry = useCallback(
    async (data: any) => {
      try {
        const result = await sendRequest({
          method: "put",
          url: `${API_BASE_URL}/countries/${data.id}/`,
          data,
        });
        setIsLoading(false);
        return result;
      } catch (err) {
        setIsLoading(false);
        console.log("ERRORRRRR", err);
        return null;
      }
    },
    [sendRequest]
  );

  const uploadCompanyLogo = useCallback(
    async (data: any, company_id: number) => {
      try {
        const response = await fetch(
          `${API_BASE_URL}/companies/logo/upload/${company_id}/`,
          {
            method: "post",
            body: data,
            headers: {
              Authorization: `Bearer ${
                user ? user?.auth.access_token : ACCESS_TOKEN
              }`,
            },
          }
        );
        setIsLoading(false);
        return response.json();
      } catch (err) {
        setIsLoading(false);
        console.log("ERRORRRRR", err);
        return null;
      }
    },
    [user]
  );
  const uploadCompanyBanner = useCallback(
    async (data: any, company_id: number) => {
      try {
        const response = await fetch(
          `${API_BASE_URL}/companies/banner/upload/${company_id}/`,
          {
            method: "post",
            body: data,
            headers: {
              Authorization: `Bearer ${
                user ? user?.auth.access_token : ACCESS_TOKEN
              }`,
            },
          }
        );
        setIsLoading(false);
        return response.json();
      } catch (err) {
        setIsLoading(false);
        console.log("ERRORRRRR", err);
        return null;
      }
    },
    [user]
  );
  const uploadCompanyRepPhoto = useCallback(
    async (data: any, company_id: number) => {
      try {
        const response = await fetch(
          `${API_BASE_URL}/companies/rep-image/upload/${company_id}/`,
          {
            method: "post",
            body: data,
            headers: {
              Authorization: `Bearer ${
                user ? user?.auth.access_token : ACCESS_TOKEN
              }`,
            },
          }
        );
        setIsLoading(false);
        return response.json();
      } catch (err) {
        setIsLoading(false);
        console.log("ERRORRRRR", err);
        return null;
      }
    },
    [user]
  );

    // QrCode
    const getQrCodes = useCallback(async (page:number=1, requestId:number) => {
      try {
        const result = await sendRequest({
          method: "get",
          url: `${API_BASE_URL}/qr-codes/?page=${page}&request_id=${requestId}`,
        });
        setIsLoading(false);
        // setRoomCategories(await result);
        return result;
      } catch (err) {
        setIsLoading(false);
        console.log("ERRORRRRR", err);
        return null;
      }
    }, [sendRequest]);

    const updateQrCode = useCallback(
      async (data: any) => {
        try {
          const result = await sendRequest({
            method: "put",
            url: `${API_BASE_URL}/qr-codes/${data.id}/`,
            data: data,
          });
          setIsLoading(false);
          return result;
        } catch (err) {
          setIsLoading(false);
          console.log("ERRORRRRR", err);
          return null;
        }
      },
      [sendRequest]
    );

    const generateQrCodes = useCallback(
      async (data: any) => {
        try {
          const result = await sendRequest({
            method: "post",
            url: `${API_BASE_URL}/qr-codes/`,
            data: data,
          });
          setIsLoading(false);
          return result;
        } catch (err) {
          setIsLoading(false);
          console.log("ERRORRRRR", err);
          return null;
        }
      },
      [sendRequest]
    );
    const scanQrCodes = useCallback(
      async (data: any) => {
        try {
          const result = await sendRequest({
            method: "post",
            url: `${API_BASE_URL}/qr-codes/${data.verification_token}/scan`,
            data: data,
          });
          setIsLoading(false);
          return result;
        } catch (err) {
          setIsLoading(false);
          console.log("ERRORRRRR", err);
          return null;
        }
      },
      [sendRequest]
    );

  // QrCode Requests
  const getQrCodeRequests = useCallback(async (page:number=1,keyword:string="") => {
    try {
      const result = await sendRequest({
        method: "get",
        url: `${API_BASE_URL}/qrcode-request/?page=${page}&keyword=${keyword}`,
      });
      setIsLoading(false);
      // setRoomCategories(await result);
      return result;
    } catch (err) {
      setIsLoading(false);
      console.log("ERRORRRRR", err);
      return null;
    }
  }, [sendRequest]);

  const getQrCodeRequest = useCallback(async (id:number) => {
    try {
      const result = await sendRequest({
        method: "get",
        url: `${API_BASE_URL}/qrcode-request/${id}`,
      });
      setIsLoading(false);
      // setRoomCategories(await result);
      return result;
    } catch (err) {
      setIsLoading(false);
      console.log("ERRORRRRR", err);
      return null;
    }
  }, [sendRequest]);

  const addQrCodeRequest = useCallback(
    async (data: any) => {
      try {
        const result = await sendRequest({
          method: "post",
          url: `${API_BASE_URL}/qrcode-request/`,
          data: data,
        });
        setIsLoading(false);
        return result;
      } catch (err) {
        setIsLoading(false);
        console.log("ERRORRRRR", err);
        return null;
      }
    },
    [sendRequest]
  );
  const updateQrCodeRequest = useCallback(
    async (data: any) => {
      try {
        const result = await sendRequest({
          method: "put",
          url: `${API_BASE_URL}/qrcode-request/${data.id}/`,
          data: data,
        });
        setIsLoading(false);
        return result;
      } catch (err) {
        setIsLoading(false);
        console.log("ERRORRRRR", err);
        return null;
      }
    },
    [sendRequest]
  );

  // Documents
  const getDocuments = useCallback(async () => {
    try {
      const result = await sendRequest({
        method: "get",
        url: `${API_BASE_URL}/documents/`,
      });
      setIsLoading(false);
      return result;
    } catch (err) {
      setIsLoading(false);
      console.log("ERRORRRRR", err);
      return [];
    }
  }, [sendRequest]);

  const getDocument = useCallback(async (id:number) => {
    try {
      const result = await sendRequest({
        method: "get",
        url: `${API_BASE_URL}/documents/${id}`,
      });
      setIsLoading(false);
      return result;
    } catch (err) {
      setIsLoading(false);
      console.log("ERRORRRRR", err);
      return [];
    }
  }, [sendRequest]);

  const addDocument = useCallback(
    async (data: any) => {
      try {
        const result = await sendRequest({
          method: "post",
          url: `${API_BASE_URL}/documents/`,
          data: data,
          files:true
        });
        setIsLoading(false);
        return result;
      } catch (err) {
        setIsLoading(false);
        console.log("ERRORRRRR", err);
        return null;
      }
    },
    [sendRequest]
  );
  const updateDocument = useCallback(
    async (data: any) => {
      try {
        const result = await sendRequest({
          method: "put",
          url: `${API_BASE_URL}/documents/${data.get('id')}/`,
          data: data,
          files:true
        });
        setIsLoading(false);
        return result;
      } catch (err) {
        setIsLoading(false);
        console.log("ERRORRRRR", err);
        return null;
      }
    },
    [sendRequest]
  );

  const uploadRoomImage = useCallback(
    async (data: any, room_id: number) => {
      try {
        const response = await fetch(
          `${API_BASE_URL}/rooms/image/upload/${room_id}/`,
          {
            method: "post",
            body: data,
            headers: {
              Authorization: `Bearer ${
                user ? user?.auth.access_token : ACCESS_TOKEN
              }`,
            },
          }
        );
        setIsLoading(false);
        return response.json();
      } catch (err) {
        setIsLoading(false);
        console.log("ERRORRRRR", err);
        return null;
      }
    },
    [user]
  );

  // Companies
  const getCompanies = useCallback(
    async (page: number = 1,keyword:string="") => {
      try {
        const result = await sendRequest({
          method: "get",
          url: `${API_BASE_URL}/companies/?page=${page}&keyword=${keyword}`,
        });
        setIsLoading(false);
        // setBookings(await result);
        return result;
      } catch (err) {
        setIsLoading(false);
        console.log("ERRORRRRR", err);
        return null;
      }
    },
    [sendRequest]
  );

  const getCompany = useCallback(
    async (id: number) => {
      try {
        const result = await sendRequest({
          method: "get",
          url: `${API_BASE_URL}/companies/${id}`,
        });
        setIsLoading(false);
        // setBookings(await result);
        return result;
      } catch (err) {
        setIsLoading(false);
        console.log("ERRORRRRR", err);
        return null;
      }
    },
    [sendRequest]
  );

  const checkInBooking = useCallback(
    async (token: string) => {
      try {
        const result = await sendRequest({
          method: "post",
          url: `${API_BASE_URL}/check-in/bookings/`,
          data: { token: token },
        });
        setIsLoading(false);
        const rsp = await result;
        return rsp;
      } catch (err) {
        setIsLoading(false);
        console.log("ERRORRRRR", err);
        return null;
      }
    },
    [sendRequest]
  );

  const updateCompany = useCallback(
    async (data: any, files:boolean=true) => {
      try {
        const result = await sendRequest({
          method: "put",
          url: `${API_BASE_URL}/companies/${data.id}/`,
          data: data,
          files:false
        });
        setIsLoading(false);
        return result;
      } catch (err) {
        setIsLoading(false);
        console.log("ERRORRRRR", err);
        return null;
      }
    },
    [sendRequest]
  );
  const addCompany = useCallback(
    async (data: any) => {
      try {
        const result = await sendRequest({
          method: "post",
          url: `${API_BASE_URL}/companies/`,
          data: data,
        });
        setIsLoading(false);
        return result;
      } catch (err) {
        setIsLoading(false);
        console.log("ERRORRRRR", err);
        return null;
      }
    },
    [sendRequest]
  );

  // payment
  const verifyPayment = useCallback(
    async (data: any) => {
      try {
        const result = await sendRequest({
          url: `${API_BASE_URL}/payments/verify/`,
          data,
          method: "post",
        });
        return result;
      } catch (err) {
        console.log("ERRORRRRR", err);
        return null;
      }
    },
    [sendRequest]
  );

  // user profile
  const getUserProfile = useCallback(async (id:number) => {
    try {
      const result = await sendRequest({
        method: "get",
        url: `${API_BASE_URL}/users/profile/${id}`,
      });
      setIsLoading(false);
      return result;
    } catch (err) {
      setIsLoading(false);
      console.log("ERRORRRRR", err);
      return [];
    }
  }, [sendRequest]);

  const updateUserProfile = useCallback(
    async (data: FormData) => {
      try {
        const result = await sendRequest({
          method: "put",
          url: `${API_BASE_URL}/users/profile/${data.get("id")}/`,
          data: data,
          files:true
        });
        setIsLoading(false);
        return result;
      } catch (err) {
        setIsLoading(false);
        console.log("ERRORRRRR", err);
        return null;
      }
    },
    [sendRequest]
  );

  // Users
  const getUsers = useCallback(async (page:number=1,keyword:string="") => {
    try {
      const result = await sendRequest({
        method: "get",
        url: `${API_BASE_URL}/system-users/?page=${page}&keyword=${keyword}`,
      });
      setIsLoading(false);
      return result;
    } catch (err) {
      setIsLoading(false);
      console.log("ERRORRRRR", err);
      return [];
    }
  }, [sendRequest]);

  const getUser = useCallback(async (id:number) => {
    try {
      const result = await sendRequest({
        method: "get",
        url: `${API_BASE_URL}/system-users/${id}`,
      });
      setIsLoading(false);
      return result;
    } catch (err) {
      setIsLoading(false);
      console.log("ERRORRRRR", err);
      return [];
    }
  }, [sendRequest]);

  const addUser = useCallback(
    async (data: any) => {
      try {
        const result = await sendRequest({
          method: "post",
          url: `${API_BASE_URL}/system-users/`,
          data: data,
          files:true
        });
        setIsLoading(false);
        return result;
      } catch (err) {
        setIsLoading(false);
        console.log("ERRORRRRR", err);
        return null;
      }
    },
    [sendRequest]
  );
  const updateUser = useCallback(
    async (data: FormData) => {
      try {
        const result = await sendRequest({
          method: "put",
          url: `${API_BASE_URL}/system-users/${data.get("id")}/`,
          data: data,
          files:true
        });
        setIsLoading(false);
        return result;
      } catch (err) {
        setIsLoading(false);
        console.log("ERRORRRRR", err);
        return null;
      }
    },
    [sendRequest]
  );

  const toggleUserActive = useCallback(
    async (data: any) => {
      try {
        const result = await sendRequest({
          method: "put",
          url: `${API_BASE_URL}/system-users/${data.id}/`,
          data: data,
          files:true
        });
        setIsLoading(false);
        return result;
      } catch (err) {
        setIsLoading(false);
        console.log("ERRORRRRR", err);
        return null;
      }
    },
    [sendRequest]
  );

  // Dealers
  const getDealers = useCallback(async (page=1,keyword:string="") => {
    try {
      const result = await sendRequest({
        method: "get",
        url: `${API_BASE_URL}/dealers/?page=${page}&keyword=${keyword}`,
      });
      setIsLoading(false);
      return result;
    } catch (err) {
      setIsLoading(false);
      console.log("ERRORRRRR", err);
      return [];
    }
  }, [sendRequest]);

  const getDealer = useCallback(
    async (id: number) => {
      try {
        const result = await sendRequest({
          method: "get",
          url: `${API_BASE_URL}/dealers/${id}`,
        });
        setIsLoading(false);
        // setBookings(await result);
        return result;
      } catch (err) {
        setIsLoading(false);
        console.log("ERRORRRRR", err);
        return null;
      }
    },
    [sendRequest]
  );

  const addDealer = useCallback(
    async (data: any) => {
      try {
        const result = await sendRequest({
          method: "post",
          url: `${API_BASE_URL}/dealers/`,
          data: data,
        });
        setIsLoading(false);
        return result;
      } catch (err) {
        setIsLoading(false);
        console.log("ERRORRRRR", err);
        return null;
      }
    },
    [sendRequest]
  );
  const updateDealer = useCallback(
    async (data: any) => {
      try {
        const result = await sendRequest({
          method: "put",
          url: `${API_BASE_URL}/dealers/${data.get('id')}/`,
          data: data,
          files:true
        });
        setIsLoading(false);
        return result;
      } catch (err) {
        setIsLoading(false);
        console.log("ERRORRRRR", err);
        return null;
      }
    },
    [sendRequest]
  );

  const uploadDealerProfilePicture = useCallback(
    async (data: any, dealer_id: number) => {
      try {
        const response = await fetch(
          `${API_BASE_URL}/dealers/image/upload/${dealer_id}/`,
          {
            method: "post",
            body: data,
            headers: {
              Authorization: `Bearer ${
                user ? user?.auth.access_token : ACCESS_TOKEN
              }`,
            },
          }
        );
        setIsLoading(false);
        return response.json();
      } catch (err) {
        setIsLoading(false);
        console.log("ERRORRRRR", err);
        return null;
      }
    },
    [user]
  );

  // Report
  const getReport = useCallback(
    async ({ startDate = "", endDate = "" }: any) => {
      try {
        const result = await sendRequest({
          method: "get",
          url: `${API_BASE_URL}/reports/?start_date=${startDate}&end_date=${endDate}`,
        });
        setIsLoading(false);
        // setReport(await result);
        return result;
      } catch (err) {
        setIsLoading(false);
        console.log("ERRORRRRR", err);
        return [];
      }
    },
    [sendRequest]
  );

  const generateReportCSV = useCallback(
    async ({ startDate = "", endDate = "" }: any) => {
      try {
        const result = await sendRequest({
          method: "get",
          url: `${API_BASE_URL}/reports/generate-report/?start_date=${startDate}&end_date=${endDate}`,
        });
        setIsLoading(false);
        // setReport(await result);
        return result;
      } catch (err) {
        setIsLoading(false);
        console.log("ERRORRRRR", err);
        return [];
      }
    },
    [sendRequest]
  );

  // Categories
  const getCategories = useCallback(async (page:number=1,keyword:string="") => {
    try {
      const result = await sendRequest({
        method: "get",
        url: `${API_BASE_URL}/categories/?page=${page}&keyword=${keyword}`,
      });
      setIsLoading(false);
      // setCategories(await result);
      return result;
    } catch (err) {
      setIsLoading(false);
      console.log("ERRORRRRR", err);
      return null;
    }
  }, [sendRequest]);

  const getCategory = useCallback(async (id:number) => {
    try {
      const result = await sendRequest({
        method: "get",
        url: `${API_BASE_URL}/categories/${id}`,
      });
      setIsLoading(false);
      // setCategories(await result);
      return result;
    } catch (err) {
      setIsLoading(false);
      console.log("ERRORRRRR", err);
      return null;
    }
  }, [sendRequest]);

  const addCategory = useCallback(
    async (data: any) => {
      try {
        const result = await sendRequest({
          method: "post",
          url: `${API_BASE_URL}/categories/`,
          data: data,
        });
        setIsLoading(false);
        return result;
      } catch (err) {
        setIsLoading(false);
        console.log("ERRORRRRR", err);
        return null;
      }
    },
    [sendRequest]
  );

  const updateCategory = useCallback(
    async (data: any) => {
      try {
        const result = await sendRequest({
          method: "put",
          url: `${API_BASE_URL}/categories/${data.id}/`,
          data: data,
        });
        setIsLoading(false);
        return result;
      } catch (err) {
        setIsLoading(false);
        console.log("ERRORRRRR", err);
        return null;
      }
    },
    [sendRequest]
  );

  // Products
  const getProducts = useCallback(
    async (page: number = 1, keyword:string="", companyId="", categoryId="") => {
      try {
        const result = await sendRequest({
          method: "get",
          url: `${API_BASE_URL}/products/?page=${page}&keyword=${keyword}&company_id=${companyId}&category_id=${categoryId}`,
        });
        setIsLoading(false);
        return result;
      } catch (err) {
        setIsLoading(false);
        console.log("ERRORRRRR", err);
        return null;
      }
    },
    [sendRequest]
  );

  const uploadProductImage = useCallback(
    async (data: any, product_id: number) => {
      try {
        const response = await fetch(
          `${API_BASE_URL}/products/image/upload/${product_id}/`,
          {
            method: "post",
            body: data,
            headers: {
              Authorization: `Bearer ${
                user ? user?.auth.access_token : ACCESS_TOKEN
              }`,
            },
          }
        );
        setIsLoading(false);
        return response.json();
      } catch (err) {
        setIsLoading(false);
        console.log("ERRORRRRR", err);
        return null;
      }
    },
    [user]
  );

  const getProduct = useCallback(
    async (id: number) => {
      try {
        const result = await sendRequest({
          method: "get",
          url: `${API_BASE_URL}/products/${id}`,
        });
        setIsLoading(false);
        return result;
      } catch (err) {
        setIsLoading(false);
        console.log("ERRORRRRR", err);
        return null;
      }
    },
    [sendRequest]
  );

  const addProduct = useCallback(
    async (data: any) => {
      try {
        const result = await sendRequest({
          method: "post",
          url: `${API_BASE_URL}/products/`,
          data: data,
        });
        setIsLoading(false);
        return result;
      } catch (err) {
        setIsLoading(false);
        console.log("ERRORRRRR", err);
        return null;
      }
    },
    [sendRequest]
  );

  const updateProduct = useCallback(
    async (data: any) => {
      try {
        const result = await sendRequest({
          method: "put",
          url: `${API_BASE_URL}/products/${data.id}/`,
          data: data,
        });
        setIsLoading(false);
        return result;
      } catch (err) {
        setIsLoading(false);
        console.log("ERRORRRRR", err);
        return null;
      }
    },
    [sendRequest]
  );

  // Sub Category
  const getSubCategories = useCallback(async (page:number=1,keyword:string="") => {
    try {
      const result = await sendRequest({
        method: "get",
        url: `${API_BASE_URL}/sub-categories/?page=${page}&keyword=${keyword}`,
      });
      setIsLoading(false);
      // setHotelManagers(await result);
      return result;
    } catch (err) {
      setIsLoading(false);
      console.log("ERRORRRRR", err);
      return null;
    }
  }, [sendRequest]);
  
  const getSubCategory = useCallback(async (id:number) => {
    try {
      const result = await sendRequest({
        method: "get",
        url: `${API_BASE_URL}/sub-categories/${id}`,
      });
      setIsLoading(false);
      // setHotelManagers(await result);
      return result;
    } catch (err) {
      setIsLoading(false);
      console.log("ERRORRRRR", err);
      return null;
    }
  }, [sendRequest]);

  const addSubCategory = useCallback(
    async (data: any) => {
      try {
        const result = await sendRequest({
          method: "post",
          url: `${API_BASE_URL}/sub-categories/`,
          data: data,
        });
        setIsLoading(false);
        return result;
      } catch (err) {
        setIsLoading(false);
        console.log("ERRORRRRR", err);
        return null;
      }
    },
    [sendRequest]
  );

  const updateSubCategory = useCallback(
    async (data: any) => {
      try {
        const result = await sendRequest({
          method: "put",
          url: `${API_BASE_URL}/sub-categories/${data.id}/`,
          data: data,
        });
        setIsLoading(false);
        return result;
      } catch (err) {
        setIsLoading(false);
        console.log("ERRORRRRR", err);
        return null;
      }
    },
    [sendRequest]
  );

  // Permission
  const getPermissions = useCallback(async (page:number=1,keyword:string="") => {
    try {
      const result = await sendRequest({
        method: "get",
        url: `${API_BASE_URL}/permissions/?page=${page}&keyword=${keyword}`,
      });
      setIsLoading(false);
      // setHotelManagers(await result);
      return result;
    } catch (err) {
      setIsLoading(false);
      console.log("ERRORRRRR", err);
      return null;
    }
  }, [sendRequest]);
  
  const getPermission = useCallback(async (id:number) => {
    try {
      const result = await sendRequest({
        method: "get",
        url: `${API_BASE_URL}/permissions/${id}`,
      });
      setIsLoading(false);
      // setHotelManagers(await result);
      return result;
    } catch (err) {
      setIsLoading(false);
      console.log("ERRORRRRR", err);
      return null;
    }
  }, [sendRequest]);

  // Role
  const getRoles = useCallback(async (page:number=1,keyword:string="") => {
    try {
      const result = await sendRequest({
        method: "get",
        url: `${API_BASE_URL}/roles/?page=${page}&keyword=${keyword}`,
      });
      setIsLoading(false);
      // setHotelManagers(await result);
      return result;
    } catch (err) {
      setIsLoading(false);
      console.log("ERRORRRRR", err);
      return null;
    }
  }, [sendRequest]);
  
  const getRole = useCallback(async (id:number) => {
    try {
      const result = await sendRequest({
        method: "get",
        url: `${API_BASE_URL}/roles/${id}`,
      });
      setIsLoading(false);
      // setHotelManagers(await result);
      return result;
    } catch (err) {
      setIsLoading(false);
      console.log("ERRORRRRR", err);
      return null;
    }
  }, [sendRequest]);

  const addRole = useCallback(
    async (data: any) => {
      try {
        const result = await sendRequest({
          method: "post",
          url: `${API_BASE_URL}/roles/`,
          data: data,
        });
        setIsLoading(false);
        return result;
      } catch (err) {
        setIsLoading(false);
        console.log("ERRORRRRR", err);
        return null;
      }
    },
    [sendRequest]
  );

  const updateRole = useCallback(
    async (data: any) => {
      try {
        const result = await sendRequest({
          method: "put",
          url: `${API_BASE_URL}/roles/${data.id}/`,
          data: data,
        });
        setIsLoading(false);
        return result;
      } catch (err) {
        setIsLoading(false);
        console.log("ERRORRRRR", err);
        return null;
      }
    },
    [sendRequest]
  );

  const { data: countries, isLoading: isCountryLoading } = useCache(
    "countries",
    getCountries
  );

  return {
    uploadCompanyBanner,
    uploadCompanyLogo,
    uploadCompanyRepPhoto,
    isLoading,
    scanQrCodes,
    getQrCodes,
    generateQrCodes,
    updateQrCode,
    getQrCodeRequests,
    getQrCodeRequest,
    updateQrCodeRequest,
    addQrCodeRequest,
    addDocument,
    updateDocument,
    getDocuments,
    getDocument,
    uploadRoomImage,
    getCompanies,
    getCompany,
    checkInBooking,
    updateCompany,
    addCompany,
    verifyPayment,
    getUsers,
    getUser,
    addUser,
    updateUser,
    toggleUserActive,
    getUserProfile,
    updateUserProfile,
    getSubCategories,
    getSubCategory,
    updateSubCategory,
    addSubCategory,
    addCategory,
    getCategories,
    getCategory,
    updateCategory,
    uploadProductImage,
    addProduct,
    updateProduct,
    getProducts,
    getProduct,
    getReport,
    generateReportCSV,
    getDealers,
    getDealer,
    addDealer,
    updateDealer,
    uploadDealerProfilePicture,
    countries,
    isCountryLoading,
    getCountry,
    getCountries,
    addCountry,
    updateCountry,
    getCities,
    getCity,
    addCity,
    updateCity,
    getAreas,
    getArea,
    addArea,
    updateArea,
    getPermissions,
    getPermission,
    getRoles,
    getRole,
    updateRole,
    addRole,
    getComplaints
  };
}
