import useSWR from "swr";
export function useCache(key: any, fetcher: any) {
  const { data, error, isLoading, mutate } = useSWR(key, fetcher);

  return {
    mutate,
    data,
    isLoading,
    isError: error,
  };
}
